<template>
  <div class="minwidth mw100">
    <el-carousel trigger="click" height="50vw" arrow="never" ref="carousel" :autoplay="autoplay">
      <el-carousel-item v-for="item in picList" :key="item.img">
        <div class="swipediv">
          <!-- <div class="swipemsg">
            <div class="fontsize50 fontsize30">{{ item.msg }}</div>
            <div class="fontsize25 fontsize15">{{ item.msg2 }}</div>
            <div class="fontsize25 fontsize15">{{ item.msg3 }}</div>
          </div> -->
          <img :src="item.img" class="swipeimg" />
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // picList: [
      //   {
      //     img: require("../../public/img/carousel/pic3_1.png"),
      //     msg: "为中国健康险进行数字化赋能",
      //     msg2: "利用人工智能算法为每一个中国人提供适合其健康需求的健康保障计划",
      //     msg3: "",
      //   },
      //   {
      //     img: require("../../public/img/carousel/pic2_1.png"),
      //     msg: "成为互联网大数据领航者",
      //     msg2: "用数据创造价值为保险、大健康、金融等领域开发大数据建模及分析系统",
      //     msg3: "",
      //   },
      //   {
      //     img: require("../../public/img/carousel/pic1_1.png"),
      //     msg: "以科技赋能商业",
      //     msg2: "搭建顶尖算法体系，提高大数据效能，创造更高价值",
      //     msg3: "",
      //   },
      // ],
      picList: [
        {
          img: require("../../public/img/carousel/pic4_1.png")
        },
        // {
        //   img: require("../../public/img/carousel/pic4_1.png")
        // },
        // {
        //   img: require("../../public/img/carousel/pic3_1.png")
        // },
        // {
        //   img: require("../../public/img/carousel/pic2_1.png"),
        // },
        // {
        //   img: require("../../public/img/carousel/pic1_1.png"),
        // },
      ],

      autoplay: true
    };
  },

  mounted(){
    this.slideBanner();
  },

  methods: {
    startAuto() {
      if (this.autoplay == false) {
        this.autoplay = true;
      }
    },
    stopAuto() {
      if (this.autoplay == true) {
        this.autoplay = false;
      }
    },
    slideBanner() {
      //选中item的盒子
      var box = document.querySelector(".el-carousel__container");
      //手指起点X坐标
      var startPoint = 0;
      //手指滑动重点X坐标
      var stopPoint = 0;

      //重置坐标
      var resetPoint = () => {
        startPoint = 0;
        stopPoint = 0;
      };

      //手指按下
      box.addEventListener("touchstart", (e) => {
        //手指按下的时候停止自动轮播
        vm.stopAuto();
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", (e) => {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      box.addEventListener("touchend", (e) => {
        console.log("1：" + startPoint);
        console.log("2：" + stopPoint);
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          vm.$refs.carousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          vm.$refs.carousel.prev();
          return;
        }
      });
    },
  },
};
</script>
  
<style scoped>
.swipediv {
  position: relative;
  height: 100%;
}

.swipemsg {
  position: absolute;
  left: 5%;
  top: 70%;
  transform: translateY(-50%);
  width: 90%;
  color: #ffffff;
}

.fontsize50 {
  font-size: 50px;
}

.fontsize25 {
  font-size: 25px;
}

.swipeimg {
  width: 100%;
  height: auto;
  z-index: -10;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* -webkit-filter: contrast(50%); */
  /* filter: contrast(50%); */
}

.minwidth {
  min-width: 1000px;
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0;
  }

  .flexnone {
    display: block;
  }

  .mw100 {
    width: 100vw;
  }

  .fontsize30 {
    font-size: 30px;
  }

  .fontsize15 {
    font-size: 15px;
  }
}
</style>

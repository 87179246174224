<template>
  <div class="minwidth">
    <tabbar></tabbar>

    <div class="positionrel w100">
      <img
        :src="require('../../../public/img/trends/pic1.png')"
        class="positionabs"
      />
    </div>

    <div class="w80 margincent margintop50 marginbot40 mw90">
      <div class="fontsize30 margincent textcent">
        深挖医疗数据价值，数鸣科技-上海交大人工智能研究院数据智能联合实验室正式揭牌
      </div>
      <div class="margintop50 textIndent">
        当前，人工智能已成为具有全球引领性的新科技，推动了数万亿级数字经济产业实现转型升级。
        正处于由高速发展阶段转为高质量发展阶段的中国，迫切需要人工智能这一强大推动力，从而催生出新的技术、产品、产业、业态和模式，引发数字资源的整体重塑和经济结构的重大变革。
        国家高度重视新一代人工智能发展，人工智能是引领这一轮科技革命和产业变革战略性的技术，具有溢出带动性很强的“头雁效应”。在中共中央制定十四五规划和2035年远景目标中，将大数据人工智能作为我国重要的战略性技术，将积极推动各产业间的深度融合。
        人工智能时代已经来临，作为一家专注于利用人工智能算法，进行大数据精准建模与商业化运营的科技服务企业，数鸣科技已经开始行动，将给医疗领域带来一场颠覆性变革。
      </div>
      <div class="textcent margintop50">
        <img
          :src="require('../../../public/img/essay/201128-1.jpeg')"
          alt=""
          class="mw80"
        />
      </div>
      <div class="w80 margincent textcent fontsize12">仪式现场</div>
      <div class="margintop50 textIndent">
        2020年11月24日，由上海数鸣人工智能科技有限公司（以下简称“数鸣科技”）与上海交通大学人工智能研究院（以下简称“研究院”）联合成立的数鸣科技-上海交大人工智能联合实验室（以下简称“联合实验室”）挂牌仪式在上海市北高新商务中心隆重举行。上海现代服务业联合会会长、原全国政协常委、上海市人大常委会副主任郑惠强，海市北高新股份有限公司总经理张羽祥，上海交通大学人工智能研究院副院长杨小康，朝衡（上海）实业有限公司董事长陈建武，上海数鸣人工智能科技有限公司总经理项亮等相关领导及企业代表出席了此次盛会，一同见证这一里程碑式的重要时刻。
      </div>
      <div class="textcent margintop50">
        <img
          :src="require('../../../public/img/essay/201128-2.jpeg')"
          alt=""
          class="mw80"
        />
      </div>
      <div class="w80 margincent textcent fontsize12">数鸣科技创始人项亮</div>
      <div class="margintop50 textIndent">
        授牌仪式上，郑惠强会长、张羽祥总经理依次致辞，表达了对于联合实验室的美好祝愿。杨小康副院长对研究院进行了详细介绍，表示将与数鸣科技建立良好合作关系，形成优势互补、互利互赢、共同发展的有利局面。作为合作伙伴，陈建武董事长预祝双方合作顺利，强调将为数鸣科技未来发展提供更多助力。
      </div>
      <div class="textcent margintop50">
        <img
          :src="require('../../../public/img/essay/201128-3.jpeg')"
          alt=""
          class="mw80"
        />
      </div>
      <div class="w80 margincent textcent fontsize12">
        数鸣科技创始人项亮（右）与杨小康副院长（左）为实验室揭牌
      </div>
      <div class="margintop50 textIndent">
        为来资本是致力于医疗大健康产业中早期投资的产业基金，基石投资人为上市公司汤臣倍健。投资方向主要围绕产业升级背景下，医疗的模式和技术创新带来的投资机会，包括创新医疗支付、医药新零售、医疗技术的进口替代等方向。
      </div>
      <div class="textcent margintop50">
        <img
          :src="require('../../../public/img/essay/201128-4.jpeg')"
          alt=""
          class="mw80"
        />
      </div>
      <div class="margintop50 textIndent">
        联合实验室创立的核心在于产业联动，依托研究院强大的科研优势以及数鸣科技口碑良好的服务平台，产生1+1大于2的合作效益。通过在算法和大数据平台上进行持续拓展，促进各方资源高度整合，为合作方提供更为优质的服务，为整个医疗行业向好向善发展贡献价值。
      </div>
      <div class="margintop50 w30 fontsize20 subhead w60">
        双向合作，加深技术领先优势
      </div>
      <div class="margintop50 textIndent">
        在项亮看来，技术是企业发展的“快速通道”，数字科技也将不遗余力地在技术开发和优化上进行大量投入。
        数鸣科技的核心技术是基于联邦学习、决策树、因子分解机、深度神经网络、k-means聚类等理论自主研发的推荐算法模型，并循环应用于完整的业务闭环中得到持续沉淀优化，在长期实践中已拥有技术、数据、应用等三大优势壁垒。
        而研究院作为国内人工智能研究领域的重点机构，集聚了大量技术性人才，占据着推动科研最前端技术发展的主导地位。
        因而联合实验室的成立，将使得双方在技术领域的集群优势进行充分融合。通过在未来开展紧密合作，以技术研发为核心板块，重点发力，促使技术趋于优化，进一步加深技术领先优势。
      </div>
      <div class="margintop50 w30 fontsize20 subhead w60">
        提供应用场景，助力科研发展
      </div>
      <div class="margintop50 textIndent">
        一项新理论的提出，往往需要通过大量实践场景来进行有效验证，这是推动科研成果转化的关键所在。
        项亮谈道，“对于科研团队来说，他们最难解决的问题就是，当一个新产品或者一个新算法出来以后，发现没有场景可以供他们使用，他们无法去验证这一产品或者这一算法真实的效果反馈，也就很难产生最后的应用价值。”
        研究院同样面临着这样的困境，而数鸣科技为此痛点提供了很好的解决方案。以自身优势为服务导向，在实际应用板块注入驱动力，通过提供大规模的场景，从而对新型理论进行规划化、可视化、系统化的科学验证，以此推动产业研究高质量发展。
        另一方面，双向结合的驱动模式，也将给数鸣科技带来诸多利好。通过打造闭环结构链，在交互过程中，持续对平台服务模式、运行模式进行深入探索，并以此为切入点，完成平台重塑和优化进程，使其在未来提供更好的服务，产生更大的价值，为科研发展提供最有力的支撑。
      </div>
      <div class="margintop50 w30 fontsize20 subhead w60">
        教学与实践落地，打造人才“储备库”
      </div>
      <div class="margintop50 textIndent">
        创新是引领发展的第一动力，而人才则是创新的第一资源。随着科技的不断进步、前沿知识的不断更新，各行各业提高了对于人才的硬性要求，同时也对人才的需求相比于任何时候都更加强烈。
        对于以团队为核心的科技型企业来说，这种现象尤为明显。
        项亮坦言，“由于行业本身的高要求和高精度，要培养一个优秀的技术人才是极其不容易的事情。而对于数鸣科技来说，我们正进入一个逐步向深度推进的关键阶段，这时候就需要在团队中补充大量高精尖人才。但在招聘过程中，我们发现从高校走出来的大学生还达不到我们的需求，因而希望通过此次合作，为自己建立一个‘人才储备库’。”
        以联合实验室为“桥梁”，数鸣科技将为学生们提供实践平台。通过放入大量具有实际应用价值且与学生研究领域息息相关的课题，使教育落地，从而拓宽知识覆盖面积，推动学生进行高效化、精准化的学习，使其逐步成长为适应企业需求的高素质人才。
        从长远发展来看，通过建立人才培养机制，将为数鸣科技在未来形成巨大的人力资源优势。后背力量的高度聚合，将为企业输送持续性的推动力，使其朝着高质量化进程迈进。
      </div>
      <div class="margintop50 w30 fontsize20 subhead w60">
        深挖数据价值，推动医疗企业发展
      </div>
      <div class="margintop50 textIndent">
        在大数据时代，数据多以半结构化和非结构化数据形式散落在各个机构，出现“散、乱、差、小”的状态。真正能够直接利用的数据不多，以至于数据量比以前大了N倍，价值密度却不高。
        而对于当前的医疗企业来说，由于缺乏核心技术和专业性人才，往往在实际操作过程中找不到数据的突破口，因而未能使数据凸显价值，这也就导致了企业深陷数据“泥潭”之中，阻碍其发展的步伐。
        为使这些深陷泥泞中的医疗企业快速上岸，数鸣科技打造了“先服务，后付费”的商业模式。以平台为引，帮助医疗企业进行数据提纯、数据规划化管理，并在此基础上为企业解决营销需求。
        以用户供给和产品特点为导向，“量身打造”最佳营销策略，使其服务精准触摸客户群，从而提高市场转化率，进一步凸显平台实际应用价值。
        值得一提的是，数据的管控权始终都在医疗企业手里，整个过程一直是在安全且透明的环境中进行，完全符合国家标准。只有在经过企业允许之后，才能打通数据在整个行业的运用渠道。
        联合实验室的成立将使这一服务的覆盖面积进一步扩大，通过将最好的专家以及最好的技术纳入其中，使实际应用效果以最佳形式呈现出来。
        未来，数鸣科技计划在联合实验室与各类医疗企业进行共建项目合作，将充分满足医疗行业的发展需求，全力清除摆在医疗行业前进道路中的“绊脚石”，推动企业步入推动企业步入快速运行车道。
      </div>
      <div class="margintop50 w30 fontsize20 subhead w60">
        数据驱动市场是未来竞争趋势
      </div>
      <div class="margintop50 textIndent">
        在项亮看来，“数据是医疗企业发展的核心驱动力，这种趋势在今后将变得更为明显。”
        随着时代的快速发展和市场需求的变化，所有的业务形态、服务模式、用户诉求都将越来越复杂，因而如何了解用户需求，帮助用户找到更好的产品，都成为了企业急需解决的难题。化解这些难题是数鸣科技的服务重点，也是数鸣科技在这个行业中最大的使命。
        数鸣科技把更多的注意力放在了技术性驱动，专注于创造价值，而不是切割价值。
        以数据为源动力，快速沉淀不同层级的市场趋势，使用户诉求得以精准呈现出来，从而帮助企业以前瞻性的视角，带着目标开发相应产品。而这样的运行机制，将使产品的市场转化率提高，另一方面，成本的大幅度削减也使其在市场竞争中处于有利地位。
        未来的趋势在于技术驱动市场，数鸣科技将致力于挖掘数据价值，以服务为核心导向，从而帮助企业解决管理体系化和结构化的问题，利用技术优势打破数据壁垒，从而建立互联互通的运行价值链条，为企业发展指引方向，推动整个医疗领域进入一个崭新的高度。
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.minwidth {
  min-width: 1000px;
}

.w100 {
  width: 100%;
}

.w90{
  width: 90%;
}

.w80 {
  width: 80%;
}

.w80 {
  width: 80%;
}

.w30 {
  width: 30%;
}

.margincent {
  margin: 0 auto;
}

.margintop50 {
  margin-top: 50px;
}

.marginbot40 {
  margin-bottom: 40px;
}

.fontsize30 {
  font-size: 30px;
}

.fontsize20 {
  font-size: 20px;
}

.textcent {
  text-align: center;
}

.subhead {
  border-bottom: 3px solid rgb(255, 0, 0);
}

.positionrel {
  position: relative;

  height: 440px;
  overflow: hidden;
}

.positionabs {
  position: absolute;
  left: 50%;
  transform: translate(-50%);

  width: 1920px;
}

.textIndent {
  text-indent: 32px;
  text-align: justify;
  line-height: 30px;
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0px;
  }

  .mw100 {
    width: 100vw;
  }

  .mw80 {
    width: 80vw;
  }

  .flexnone {
    display: block;
  }

  .w60 {
    width: 60%;
  }

  .fontsize12 {
    font-size: 12px;
  }

  .positionrel {
    position: relative;

    height: 179px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 780px;
  }

  .mw90 {
    width: 90%
  }
}
</style>
<template>
  <div class="minwidth mw100">
    <tabbar></tabbar>

    <div class="positionrel w100">
      <img
        :src="require('../../../public/img/trends/pic1.png')"
        class="positionabs"
      />
    </div>

    <div class="w80 margincent margintop50 marginbot40 mw90">
      <div class="fontsize30 margincent textcent">
        对标GoHealth，数鸣科技完成过亿元A轮融资，医疗健康市场门外的“算法野蛮人”
      </div>
      <div class="margintop50 textIndent">
        动脉网第一时间获悉，3月9日，人工智能算法公司数鸣科技宣布完成过亿元A轮融资，由为来资本、泽悦资本联合领投。本轮资金将用于人工智能算法及SaaS服务平台的研发投入、扩展技术运营团队。数鸣科技于2017年成立于上海，是一家以保险及大健康用户行为预测及商业化为核心业务的人工智能算法公司。其核心技术在于基于多数据源融合，利用人工智能算法进行用户消费行为预测分析。
        数鸣从依托公域流量再到私域流量，提供技术能力的同时，反向整合分散的私域流量，进而深挖用户价值、精细化运营。利用人工智能算法能力，数鸣科技已形成了两条业务线：
        第一，通过独有的“联邦建模”技术平台，重新设计了最佳的投保流程和用户匹配模型，帮助C端客户找到最佳的、适合其特定需求的医疗保险计划/产品；
        第二，向传统保险公司，及创新互联网保险平台提供针对保险公司存量用户数据的标签化、结构化能力，协助保险公司提升存量用户的二次转化率和LTV（用户终身价值），并与保险公司共同优化、定制保险产品。
      </div>
      <div class="margintop50 w30 margincent textcent subhead w60 fontsize25" >
        建立保险经纪模式，差异化掘金健康险市场
      </div>
      <div class="margintop50 textIndent">
        我国的健康险企业，正迎来快速发展的阶段。过去5年，我国健康险保费收入复合增长率超过30%；2020年前三季度，健康险保费收入6.666亿元，较去年同期增长17.4%。据2020年初银保监会发布的文件，2025年我国健康险市场要达到2万亿元的规模。
        密集的政策出台，也凸显着健康险在医疗支付领域的重要地位。近半年多个重磅级政策的出台，其背后的政策逻辑是医保基金提升支付效率，在医保基金的整体运行上开源节流。按照我国当前的人口老龄化趋势，未来居民医疗支出快速增长压力较大，可能会因此出现一个较大的缺口，而这个缺口需要新的多元支付体系，一是有效增加健康保障收入，二是增强健康保障支付效率，三是通过健康服务实现有效风险管理，提升用户体验，商业健康险因其自身优势一定会在医疗多元化支付体系中占据越来越重要的地位。
        数鸣科技顺势而为进入健康险行业，通过为保险公司服务进行差异化竞争，解决信息不对称、专业化程度低、盈利能力低等问题。结合计算、数据科学和深厚的行业专业知识，数鸣科技将消费者和保险公司联系起来，利用近二十年的保险行为数据提供支持的机器学习算法，重新构想最佳流程，以帮助个人找到适合其特定需求的最佳健康保险计划，加强了潜在用户的推广、提高透明度和提供更多选择。
        数鸣科技应用独有的机器学习技术来实时定位、评估以及优先处理所有的用户行为数据，同时从每一次与用户的互动中生成的数据有助于与数鸣的营销状况、潜在用户转化和健康保险计划匹配形成正反馈。至2020年年底，数鸣单月年化保费已破亿，复合增速超过100％。
      </div>
      <div class="margintop50 w30 margincent textcent subhead w60 fontsize25">
        专业团队加持，变现模式已清晰
      </div>
      <div class="margintop50 textIndent">
        数鸣科技核心团队由拥有15年以上人工智能技术开发及场景应用专家组成。创始人项亮15年的互联网从业经历，对人工智能算法在金融、消费领域的应用有丰富的经验。
        至今，数鸣科技已建成三条清晰的商业变现模式，具备强劲的盈利转化能力。
        第一，健康人群短险获客，长险转化，同时通过“自营产品获客+创新保险服务平台”合作模式获得一定比例的佣金收入。
        第二，带病体保险获客，供应链产品转化。数鸣科技将糖尿病、高血压、甲状腺结节、生殖健康等不同的带病人群筛出，配以对应的保险产品，也可与保司合作进行产品开发并独家代理，更重要的是可为带病人群提供药品福利计划/PBM，实现后端供应链产品的超额收益。
        第三，私域流量代运营。一方面，数鸣通过独有的“联邦建模”计算平台建模清洗用户，将私域流量运营方的存量用户标签化，通过电话、短信、信息流投放方式做二次转化，按效果获得分佣。另一方面，数鸣负责保险板块整体营销，则保险转化和供应链产品导入获得的收入与私域流量运营方进行分佣。
        此外，随着业务量的增多，数鸣科技AI算法会有源源不断的学习样本集和真实的成单反馈结果，以及算法的预测值进行对比和校正，不断贴近实际业务需求。
        数鸣科技表示，通过本轮融资，数鸣科技在算法优化、投放规模、市场开拓方面将获得大幅提升，将对现有的推广获客业务带来显著的收入利润增长。数鸣科技预测将上半年完成首批客户SaaS服务平台的实施级试运营。
        为来资本合伙人赵阳表示，中国医疗产业正在由医院院内市场向院外用户市场快速切换，底层商业逻辑正从传统的“企业用户市场“向“个人用户市场“转变，个人用户有更大的话语权选择合适的医疗和支付方案。由此带来的是医药工业、渠道和医疗服务场景增长模式的彻底改变。推动这一变革的力量是多元的，既有国家医保局和银保监会的顶层设计，也有像数鸣科技这类新技术企业的跨域整合。
        “我们在多年前就提出，彻底改变中国医疗产业生态面貌的很可能是传统医疗产业以外的‘算法野蛮人’。在对数鸣科技的尽调中，我们发现‘可能’变成了现实。这是一个非常精悍的技术团队，对底层技术创新和应用场景的把握非常准确，他们的算法模型在金融、游戏、消费领域积淀多年，同时有成熟的打法和团队支撑对新领域的快速覆盖。这也就不难想象一个完全没有保险和医疗背景的团队，通过算法快速成为保险行业顶级的流量入口，在不到6个月时间里形成了数亿的保费规模。”赵阳说道，“我们相信，保险行业只是开始，接下来数鸣科技非常有机会在医药供应链和医疗服务领域复制保险市场的成功案例，切入新的万亿市场。”
        泽悦资本的创始合伙人吕晓翔表示：“当前我国健康险仍处于占比低、渗透率低的初级阶段，商业健康保险行业的发展潜力巨大。伴随着商业保险的成长，像数鸣此类的AI精准投放公司的前途也拥有无限的想象空间。数鸣拥有专业的团队、优秀的技术、和高效的业务模式，是行业中的佼佼者。”
      </div>
      <div class="margintop50 w30 margincent textcent subhead w60 fontsize25">
        关于为来资本
      </div>
      <div class="margintop50 textIndent">
        为来资本是致力于医疗大健康产业中早期投资的产业基金，基石投资人为上市公司汤臣倍健。投资方向主要围绕产业升级背景下，医疗的模式和技术创新带来的投资机会，包括创新医疗支付、医药新零售、医疗技术的进口替代等方向。
      </div>
      <div class="margintop50 w30 margincent textcent subhead w60 fontsize25">
        关于泽悦资本
      </div>
      <div class="margintop50 textIndent">
        泽悦资本是一家专注于医疗大健康领域的股权投资机构，由极富行业经验的资深人士共同创立，拥有丰富的产业资源和独特的投资理念。创始人基于对中国医疗环境，医改趋势的深刻洞见，整合来自研发、生产、营销等产
        业资源，在全球范围内寻找具有“中国角度”及持续发展潜力的创业项目，并以创造长期价值为基础作出投资决策。除了资本的投入，泽悦的目标是成为“参与型”的共同创业伙伴。基于对行业发展趋势的深刻理解和判断，时刻保持对于市场机会和风险的敏锐度。投资组合覆盖了生物医药，医疗器械和医疗服务优质项目。
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.minwidth {
  min-width: 1000px;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%
}

.w70 {
  width: 70%;
}

.w30 {
  width: 35%;
}

.margincent {
  margin: 0 auto;
}

.margintop50 {
  margin-top: 50px;
}

.marginbot40 {
  margin-bottom: 40px;
}

.fontsize30 {
  font-size: 30px;
}

.fontsize25 {
  font-size: 25px
}


.textcent {
  text-align: center;
}

.subhead {
  border-bottom: 3px solid rgb(255, 0, 0);
}

.positionrel {
  position: relative;

  height: 440px;
  overflow: hidden;
}

.positionabs {
  position: absolute;
  left: 50%;
  transform: translate(-50%);

  width: 1920px;
}

.textIndent {
  text-indent: 32px;
  text-align: justify;
  line-height: 30px;
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0px;
  }

  .mw100 {
    width: 100vw;
  }

  .flexnone {
    display: block;
  }

  .w60 {
    width: 60%;
  }

  .positionrel {
    position: relative;

    height: 179px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 780px;
  }

  .mw90 {
    width: 90%
  }
}
</style>
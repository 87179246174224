<template>
  <div class="minwidth w100 mw100">
    <tabbar></tabbar>

    <div class="positionrel w100">
      <img :src="require('../../public/img/join/pic1.png')" class="positionabs" />
      <div style="color: #fff;font-size: 3rem;position: absolute;top: 8rem;left: 50%;transform: translate(-50%);">
        注册
      </div>
    </div>

    <div style="width: 20%;margin: 0 auto;padding: 2rem 0;">
      <div style="width: 100%;margin: 0 auto;display: flex;justify-content: center;align-items: center;height: 3rem;">
        <div style="width: 20%;"> 
          账号：
        </div>
        <input style="width: 60%;display: block;height: 1.5rem;" type="text">
      </div>
      <div style="width: 100%;margin: 0 auto;display: flex;justify-content: center;align-items: center;height: 3rem;">
        <div style="width: 20%;">
          密码：
        </div>
        <input style="width: 60%;display: block;height: 1.5rem;" type="text">
      </div>
      <div style="width: 85%;margin: 0 auto;text-align: center;background-color: #4571DB;height: 2.5rem;line-height: 2.5rem;border-radius: 1rem;margin-top: 1rem;color: #fff;">
        注册
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 0,
    };
  },

  mounted() {
    this.num = this.$route.query.num;
  },

  methods: {
    gotoNewsDetail(num) {
      this.$router.push({ path: '/newsDetail', query: { num: num } })
    }
  },

  
};
</script>

<style scoped>
.w100 {
  width: 100%;
}

.w60 {
  width: 60%;
}

.minwidth {
  min-width: 1000px;
}

.flex {
  display: flex;
}

.textcent {
  text-align: center;
}

.textalign_justify {
  text-align: justify;
  line-height: 25px;
}

.fontsize50 {
  font-size: 50px;
}

.fontsize30 {
  font-size: 30px;
}

.fontsize20 {
  font-size: 20px;
}

.fontsize16 {
  font-size: 16px;
}

.fontsize14 {
  font-size: 14px;
}

.fontsize12 {
  font-size: 12px;
}

.fontcolorb0 {
  color: #b0b0b0;
}

.margincent {
  margin: 0 auto;
}

.margincentY {
  margin: auto 0;
}

.margintop50 {
  margin-top: 50px;
}

.margintop70 {
  margin-top: 70px;
}

.marginleft20 {
  margin-left: 20px;
}

.marginbot70 {
  margin-bottom: 70px;
}

#job:hover {
  color: #bc9158;
}

.positionrel {
  position: relative;
  height: 340px;
  overflow: hidden;
}

.positionabs {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 1920px;
  -webkit-filter: contrast(50%);
  filter: contrast(50%);
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0px;
  }

  .mw100 {
    width: 100vw;
  }

  .flexnone {
    display: block;
  }

  .w80 {
    width: 80%;
  }

  .positionrel {
    position: relative;

    height: 179px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 780px;
  }
}
</style>
<template>
  <div class="minwidth mw100">
    <el-carousel
      trigger="click"
      direction="vertical"
      height="780px"
      arrow="never"
      interval="8000"
    >
      <el-carousel-item v-for="item in picList" :key="item.img">
        <div class="swipediv">
          <div class="swipemsg">
            <img :src="item.imgS" style="height: 100px" />
            <div class="msg fontsize30">
              {{ item.msg }}
            </div>
            <div class="msg2 fontsize15 linheight30 textAlign_justify">
              {{ item.msg2 }}
            </div>
          </div>
          <img :src="item.img" class="swipeimg showimg1" />
          <img src="" class="showimg2" />
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picList: [
        {
          img: require("../../public/img/carousel2/pic1-0.png"),
          imgS: require("../../public/img/carousel2/pic1-2.png"),
          msg: "汤臣倍健 科学营养",
          msg2: "汤臣倍健创立于1995年，2002年系统地将膳食营养补充剂（VDS）引入中国非直销领域，2010年12月15日，汤臣倍健在深圳交易所创业板挂牌上市，并迅速成长为中国膳食营养补充剂领导品牌和标杆企业。2020年汤臣倍健以10.3%的市场份额位居中国维生素与膳食补充剂行业第一位。汤臣倍健逐步发展为全球膳食营养补充剂行业领先者。",
        },
        {
          img: require("../../public/img/carousel2/pic2-0.png"),
          imgS: require("../../public/img/carousel2/pic2-2.png"),
          msg: "众安保险 科技驱动金融 做有温度的保险",
          msg2: "众安在线财产保险股份有限公司是中国首家互联网保险公司，于2013年11月6日揭牌开业，2017年9月28日在香港联交所主板上市。众安保险完全通过互联网展业。由“保险+科技”双引擎驱动，众安专注于应用新技术重塑保险价值链，围绕健康、数字生活、消费金融、汽车四大生态，以科技服务新生代，为其提供个性化、定制化、智能化的新保险。",
        },
        {
          img: require("../../public/img/carousel2/pic3-0.png"),
          imgS: require("../../public/img/carousel2/pic3-2.png"),
          msg: "泰康人寿 让生命的的旅程流光溢彩",
          msg2: "泰康人寿保险有限责任公司（简称泰康人寿）是泰康保险集团股份有限公司（简称泰康保险集团）的子公司。截止2020年12月31日，公司总资产超9950亿元，净资产超725亿，规模保费超1880亿元。泰康人寿始终坚持“专业化、市场化、规范化”的企业价值观，坚持稳健经营、开拓创新的经营理念，致力于为日益成长的工薪白领人群提供专业化、高品质的人寿保险服务。",
        },
        {
          img: require("../../public/img/carousel2/pic4-0.png"),
          imgS: require("../../public/img/carousel2/pic4-2.png"),
          msg: "中信银行",
          msg2: "中信银行是中国改革开放中最早成立的新兴商业银行之一，是中国最早参与国内外金融市场融资的商业银行。中信银行以建设成为“有担当、有温度、有特色、有价值”的最佳综合金融服务提供者为发展愿景，充分发挥中信集团“金融+实业”综合平台优势，坚持“以客为尊、改革推动、科技兴行、轻型发展、合规经营、人才强行”",
        },
        {
          img: require("../../public/img/carousel2/pic5-0.png"),
          imgS: require("../../public/img/carousel2/pic5-2.png"),
          msg: "中国平安 成为国际领先的个人金融生活服务集团",
          msg2: "中国平安是国内金融牌照最齐全、业务范围最广泛的个人金融生活服务集团之一，目前集团总资产突破10万亿元，是全球资产规模最大的保险集团。坚持“科技引领金融，金融服务生活”的理念，持续深化“金融+科技”、探索“金融+生态”，聚焦“大金融资产”和“大医疗健康”两大产业，并深度应用于“金融服务、医疗健康、汽车服务、城市服务”生态圈，为客户创造“专业，让生活更简单”的品牌体验，让客户的金融生活“省心、省时又省钱”，实现高质量可持续发展。",
        },
        {
          img: require("../../public/img/carousel2/pic6-0.png"),
          imgS: require("../../public/img/carousel2/pic6-2.png"),
          msg: "中国银联",
          msg2: "中国银联成立以来，在中国人民银行的领导下，联合产业各方，圆满完成了“联网通用”的历史使命，成功创建了“银联”自主品牌，在服务经济社会发展、改善民生、提升金融服务水平、促进我国现代化支付体系建设等方面，发挥了重要作用。中国银联在国际国内形成了较强的品牌影响力和市场竞争力，已成为世界三大银行卡品牌之一，也是国际影响力最大的国内品牌之一。",
        },
        {
          img: require("../../public/img/carousel2/pic7-0.png"),
          imgS: require("../../public/img/carousel2/pic7-2.png"),
          msg: "哈啰出行 行好生活每一程",
          msg2: "国内专业的本地出行及生活服务平台，致力于应用数字技术的红利，为人们提供更便捷的出行以及更好的普惠生活服务。公司于2016年9月在上海成立，从大家熟悉的共享单车业务起步，逐渐进化为包括两轮出行、四轮出行、酒旅以及到店服务等多元化的出行及生活服务平台。截至2020年年底，哈啰出行旗下哈啰共享两轮业务共进驻全国超400城（含县级市），用户累计骑行240亿公里，累计减少碳排放66.7万吨；哈啰顺风车已覆盖全国超300城，认证车主逾千万名。",
        },
      ],
    };
  },
};
</script>

<style scoped>
.swipediv {
  position: relative;
  height: 100%;
}

.swipemsg {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 90%;
}

.swipeimg {
  width: 800px;
  z-index: -99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}

.msg {
  font-size: 30px;
  margin-top: 20px;
  width: 550px;
}

.msg2 {
  font-size: 15px;
  margin-top: 20px;
  width: 550px;
}

.fontsize50 {
  font-size: 50px;
}

.fontsize25 {
  font-size: 25px;
}

.linheight30 {
  line-height: 30px;
}

.el-carousel__item {
  background-color: #d3dce6;
}

.el-carousel >>> .el-carousel__indicator:nth-child(1) > button {
  background-image: url("../../public/img/carousel2/pic1-1.png");
}
.el-carousel >>> .el-carousel__indicator:nth-child(2) > button {
  background-image: url("../../public/img/carousel2/pic2-1.png");
}
.el-carousel >>> .el-carousel__indicator:nth-child(3) > button {
  background-image: url("../../public/img/carousel2/pic3-1.png");
}
.el-carousel >>> .el-carousel__indicator:nth-child(4) > button {
  background-image: url("../../public/img/carousel2/pic4-1.png");
}
.el-carousel >>> .el-carousel__indicator:nth-child(5) > button {
  background-image: url("../../public/img/carousel2/pic5-1.png");
}
.el-carousel >>> .el-carousel__indicator:nth-child(6) > button {
  background-image: url("../../public/img/carousel2/pic6-1.png");
}
.el-carousel >>> .el-carousel__indicator:nth-child(7) > button {
  background-image: url("../../public/img/carousel2/pic7-1.png");
}

.el-carousel >>> .el-carousel__indicator {
  width: 50px;
  height: 80px;
}

.el-carousel >>> .el-carousel__indicator > button {
  width: 50px;
  height: 50px;
  background-size: 50px 50px;
  border-radius: 10px;
}

.minwidth {
  min-width: 1000px;
}

.showimg1 {
  display: block;
}

.showimg2 {
  display: none;
}

.textAlign_justify {
  text-align: justify;
}

@media (min-width: 750px) and (max-width: 1400px) {
  .msg {
    font-size: 30px;
    margin-top: 20px;
    width: 350px;
  }

  .msg2 {
    font-size: 15px;
    margin-top: 20px;
    width: 350px;
  }

  .swipeimg {
    width: 600px;
    z-index: -99;
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0;
  }

  .flexnone {
    display: block;
  }

  .mw100 {
    width: 100vw;
  }

  .fontsize30 {
    font-size: 30px;
  }

  .fontsize15 {
    font-size: 15px;
  }

  .swipemsg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100vw;
  }

  .swipeimg {
    width: 600px;
    z-index: -99;
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translateY(-50%);
  }

  .msg {
    font-size: 30px;
    margin-top: 20px;
    width: 70vw;
  }

  .msg2 {
    font-size: 15px;
    margin-top: 20px;
    width: 70vw;
  }

  .el-carousel >>> .el-carousel__indicator {
    width: 30px;
    height: 40px;
  }

  .el-carousel >>> .el-carousel__indicator > button {
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
    border-radius: 10px;
  }

  .showimg1 {
    display: none;
  }

  .showimg2 {
    display: block;
  }
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Tabbar from './components/Tabbar.vue'
Vue.component("tabbar",Tabbar)

import Bottom from './components/Bottom.vue'
Vue.component("bottom",Bottom)

import Carousel from './components/Carousel.vue'
Vue.component("carousel",Carousel)

import Carousel2 from  './components/Carousel2.vue'
Vue.component("carousel2",Carousel2)

import Customerservice from  './components/Customerservice.vue'
Vue.component("customerservice",Customerservice)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)


// import 'lib-flexible'

Vue.config.productionTip = false

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="minwidth">
    <tabbar></tabbar>

    <div class="positionrel w100">
      <img
        :src="require('../../../public/img/trends/pic1.png')"
        class="positionabs"
      />
    </div>

    <div class="w80 margincent margintop50 marginbot40 mw90">
      <div class="fontsize30 margincent textcent">
        见证生命新势力，第四届SATOL生命科技创新创业论坛于济南成功举办
      </div>
      <div class="margintop50 textIndent">
        11月7日，第四届SATOL生命科技创新创业论坛以“生命新势力”为主题，在泉城山东济南市圆满落幕。活动旨在提供一个深度交流、合作共赢的平台，加快健康医疗行业资源的共享开放、互联互通，推动健康医疗行业的创新应用和产业发展。
        论坛由中国工程院、济南市人民政府、浙江大学主办，中华医学会、中国医师协会协办。活动融合了主题演讲、项目路演、圆桌交流等多种形式，建立有效的多方对话机制，加强生命科技与临床医学、产业金融的融合发展，并嘉奖创新创业的优秀团队，推动产业各方携手打造生命科技领域的技术新应用、领域新业态及商业新模式。
      </div>
      <div class="margintop50 w30 margincent textcent subhead w60 fontsize25">
        院士及嘉宾颁奖，树立生命科技创新标杆
      </div>
      <div class="margintop50 textIndent">
        SATOL全球医学加速营迄今已举办3届，目前成为生命科技创新创业企业广泛参与，行业高度关注的创新标杆活动。随着生命科技领域的创新体系建设不断完善，科研转化及产品研发不断获得突破，优质创新成果及初创项目不断涌现。本届创新创业大赛初赛胜出的20家优秀企业，涵盖生物医药、医疗器械、数字健康、创新疗法四大领域。
        在上午的世界生命科技大会开幕式上，山东省副省长孙继业、济南市政协主席雷杰、杨胜利院士、李兰娟院士、张伯礼院士、徐建国院士、郑静晨院士、张志愿院士、顾晓松院士和树兰医疗管理集团总裁郑杰为10个生命科技创新榜企业颁发了奖项。
      </div>
      <div class="textcent margintop50">
        <img
          :src="require('../../../public/img/essay/201110-1.png')"
          alt=""
          class="mw80"
        />
      </div>
      <div class="w80 margincent textcent fontsize12">
        SATOL医创营活动生命科技创新奖颁奖现场
      </div>
      <div class="margintop50 textIndent">
        紧接着，山东省人大常委会副主任王云鹏、济南市人大常委会主任殷鲁谦、谢立信院士、贺林院士、王军志院士、刘良院士、田伟院士、尚红院士、浙江大学发展委员会副主席陈子辰和齐鲁制药集团总裁李燕为另外10个项目颁发了生命科技创业奖。
      </div>
      <div class="textcent margintop50">
        <img
          :src="require('../../../public/img/essay/201110-2.png')"
          alt=""
          class="mw80"
        />
      </div>
      <div class="w80 margincent textcent fontsize12">
        SATOL医创营活动生命科技创业奖颁奖现场
      </div>
      <div class="margintop50 w30 margincent textcent subhead w60 fontsize25">
        大咖齐聚，探讨产业发展新模式
      </div>
      <div class="margintop50 textIndent">
        作为世界生命科技大会的分论坛之一，7日下午第四届SATOL生命科技创新创业论坛邀请到了中国工程院院士、传染病学专家李兰娟，以及山东省济南市政协主席雷杰作为开场嘉宾致辞。
      </div>
      <div class="textcent margintop50">
        <img
          :src="require('../../../public/img/essay/201110-3.png')"
          alt=""
          class="mw80"
        />
      </div>
      <div class="w80 margincent textcent fontsize12">
        中国工程院院士 李兰娟
      </div>
      <div class="margintop50 textIndent">
        李兰娟院士在致辞中表示，秉持“生命至上”的初心，未来医疗产业需要与不同领域技术跨界共生，融合创新。同时，李院士也鼓励初创企业积极拓展生命科技的新边界，勇于实现新突破、创造新佳绩，从而把健康产业建成国民经济重要支柱产业。
      </div>
      <div class="textcent margintop50">
        <img
          :src="require('../../../public/img/essay/201110-4.png')"
          alt=""
          class="mw80"
        />
      </div>
      <div class="w80 margincent textcent fontsize12">济南市政协主席 雷杰</div>
      <div class="margintop50 w30 margincent textcent subhead w60 fontsize25">
        双向合作，加深技术领先优势
      </div>
      <div class="margintop50 textIndent">
        雷杰主席在致辞中点明了发展生命科技产业，实现多产业协同发展的济南思路。基于济南优质产业资源，东部依托国家山东创新药物孵化基地建设项目组成的济南药谷，西部省市共建的重大健康产业项目济南国际医学科学中心，联动尖端技术，实现新旧动能的转化。
      </div>
      <div class="textcent margintop50">
        <img
          :src="require('../../../public/img/essay/201110-5.png')"
          alt=""
          class="mw80"
        />
      </div>
      <div class="w80 margincent textcent fontsize12">圆桌论坛精彩瞬间</div>
      <div class="margintop50 textIndent">
        在论坛的圆桌讨论环节中，围绕“生命科技创新创业的产业联动”议题，由树兰医疗管理集团总裁郑杰主持，政府、产业及投资方代表面对面，从创新创业团队、到孵化成长的产业环境，再宏观趋势预判，各位嘉宾针对生命科技项目的发展需求以及医疗企业的发展方向，产业各方所应该扮演的角色，进行了深刻而广泛的探讨。
      </div>
      <div class="margintop50 w30 margincent textcent subhead w60 fontsize25">
        新势力碰撞，迸发生命科技的新活力
      </div>
      <div class="margintop50 textIndent">
        下午的分论坛现场进行决赛轮的路演，20家企业充分展现了其在行业内的产品技术、临床场景以及商业模式的创新及突破，收获了现场专家及同行的广泛好评。现场来自产业以及专业医疗投资机构的评审团表示，中国庞大的市场、快速发展的科技以及巨量资本的入场，给生命科技产业的提供了全新的机遇，希望越来越多的企业和团队能够深耕生命科技领域，成长为医学进步和创新发展的新势力，为产业发展提供新动能。
      </div>
      <div class="textcent margintop50">
        <img
          :src="require('../../../public/img/essay/201110-6.png')"
          alt=""
          class="mw80"
        />
      </div>
      <div class="margintop50 textIndent">
        通过现场三十余名评审嘉宾的评审，最终，杭州莱普晟医疗科技有限公司获得生命科技创业奖一等奖；深圳泰莱生物科技有限公司、上海数鸣人工智能科技有限公司获得二等奖，南京仁迈生物科技有限公司、上海爱谨人工智能科技有限公司、博源润生医药（杭州）有限公司获得了三等奖。
        在创新榜中，杭州脉流科技有限公司荣获生命科技创新奖一等奖；慕恩（广州）生物科技有限公司、北京卡替医疗技术有限公司获得二等奖；中生康元生物科技（北京）有限公司、杭州阿泰克生物科技有限公司、深圳康源久远生物技术有限公司获得了三等奖。
        本届论坛聚集了科学界、产业界、投资界及医疗界的精英，与生命科技领域优秀从业者一道，分享产业发展趋势，共商融合联动发展。面对新形势，论坛从产业创新、培养赋能优质企业、加强政研协作、促进交叉领域研究等方面，融合产业及资本资源，为推动人类健康和社会可持续发展提供有力支撑，为增进人民群众健康福祉作出了新贡献。
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.minwidth {
  min-width: 1000px;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.w30 {
  width: 30%;
}

.margincent {
  margin: 0 auto;
}

.margintop50 {
  margin-top: 50px;
}

.marginbot40 {
  margin-bottom: 40px;
}

.fontsize30 {
  font-size: 30px;
}

.fontsize20 {
  font-size: 20px;
}

.fontsize25 {
  font-size: 25px;
}

.textcent {
  text-align: center;
}

.subhead {
  border-bottom: 3px solid rgb(255, 0, 0);
}

.positionrel {
  position: relative;

  height: 440px;
  overflow: hidden;
}

.positionabs {
  position: absolute;
  left: 50%;
  transform: translate(-50%);

  width: 1920px;
}

.textIndent {
  text-indent: 32px;
  text-align: justify;
  line-height: 30px;
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0px;
  }

  .mw100 {
    width: 100vw;
  }

  .mw80 {
    width: 80vw;
  }

  .flexnone {
    display: block;
  }

  .w60 {
    width: 60%;
  }

  .fontsize12 {
    font-size: 12px;
  }

  .positionrel {
    position: relative;

    height: 179px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 780px;
  }

  .mw90 {
    width: 90%
  }
}
</style>
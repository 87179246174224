<template>
  <div class="minwidth">
    <tabbar></tabbar>

    <div class="positionrel w100">
      <img
        :src="require('../../../public/img/trends/pic1.png')"
        class="positionabs"
      />
    </div>

    <div class="w80 margincent margintop50 marginbot40 mw90">
      <div class="fontsize30 margincent textcent">
        数鸣科技：为医疗企业提供智能营销、运营服务，2020年净利润预计超2000万元
      </div>
      <div class="margintop50 textIndent">
        从结绳记事到羊皮记史，从单台电子计算机有限的磁盘存储到如今占地数百公顷的数据中心，随着数据存储方式的改变，数据的体量也在无限扩大。
        随着医院内部临床数据存储规模的扩大，多样和冗杂的特点也开始显现，但数据的“高”价值还深藏于底。
        “大数据具有价值密度低的特点。”这是世界大数据商用之父舍恩伯格提出的一个观点。在大数据时代，数据多以半结构化和非结构化数据形式散落在各个机构，出现“散、乱、差、小”的状态。真正能够直接利用的数据不多，以至于数据量比以前大了N倍，价值密度却不高。
        “我们的目的就是利用人工智能技术唤醒出医疗数据中的价值数据，对其进行处理、优化，然后提炼出来实现共享。”数鸣科技创始人项亮如是说。
        数鸣科技于2017年在上海成立，是一家专注于利用人工智能算法，进行大数据精准建模与商业化运营的科技服务企业。
        短短3年，数鸣科技已被业界广泛认可，业务覆盖医疗健康、消费金融、电子竞技、汽车、教育等八大行业。仅在医疗健康险领域，数鸣科技作为专业化ToC平台，就已经与众安、水滴等头部互联网险企深度绑定，成为其Top1-3的销售渠道之一。
      </div>
      <div class="margintop50 w30 margincent textcent subhead w60 fontsize25">
        与在线保险前三合作，3年后服务规模或达10亿元
      </div>
      <div class="margintop50 textIndent">
        这是项亮的首次创业。在此之前，项亮就职于中国三大运营商之一联通，长期担任广告事业部总经理职位，对于金融领域精准营销、人工智能应用、大数据应用有着深刻理解。
        也是在筹备运营商与金融、险企等合作落地的工作经历中，他察觉到了一些创业机会。作为国内最早使用人工智能算法、运营商大数据技术进行用户行为预测的团队之一，数鸣科技创始团队掌握着大量的数据资源，并且拥有深厚的技术支持。彼时，人工智能、互联网创业风潮正盛。于是，踏着热潮的浪花，项亮团队走上了人工智能的创业道路。
        在数鸣科技八大服务体系中，医疗板块是创立始便确定深耕的领域。在项亮看来，原因有三：
        其一，医疗属于常青行业，拥有持续增长的市场空间；其二，近年来，健康险已成为增速最快险种，政策支持、市场空间巨大；其三，医疗是一个严肃行业，需要“硬科技”，与数鸣科技深厚的技术基础非常契合。
        在医疗健康险业务板块，数鸣科技已与头部互联网保险企业达成合作，提供在线短险推广+长险运营服务，在价值率高的销售环节与头部公司深度绑定，成为多家在线保险公司排名前三的重要合作伙伴，未来3年后服务费收入规模有望达到10亿元。
      </div>
      <div class="margintop50 w30 margincent textcent subhead w60 fontsize25">
        建三大技术壁垒，人工智能算法提升营销转化
      </div>
      <div class="margintop50 textIndent">
        短短三年，数鸣科技能够获得众多行业中的头部企业青睐，项亮对数鸣科技的优势做了四点总结：
        先进算法是数鸣科技核心优势所在。数鸣的核心技术是基于联邦学习、决策树、因子分解机、深度神经网络、k-means聚类等理论自主研发的推荐算法模型，并循环应用于完整的业务闭环中得到持续沉淀优化，在长期实践中已拥有技术、数据、应用等三大优势壁垒。
        数鸣在行业内开拓性地搭建了基于多数据源和多触达方式的推荐系统，并将决策树、因子分解机、神经网络等机器学习算法应用在了八大业务线上。同时，数鸣科技还与上海人工智能研究院、上海交通大学人工智能研究院，广泛开展在算法层面的合作，多名博士硕士组成的算法研究团队长期攻关。
        海量数据和标签为数鸣科技开展业务打下坚实基础。自2017年起，数鸣科技便与数鸣咨询分享平台知犀APP及合作平台，运营留存意向用户群，并通过外部合作进行客户群导流，完成客户沉淀及结构化和标签化，通过为合作机构提供代运营服务，获得经授权后的客户数据库。
        另外，数鸣科技还与中国银联及11家股份制银行，以及头部互联网保险企业达成长期深度合作，所积淀的大规模数据也对数鸣科技算法训练优化奠定数据基础。
        数鸣科技还与运营商达成合作，为其提供算法能力输出，并由此构成了适配数鸣科技业务的精准用户画像能力。值得注意的是，数鸣科技所涉及到的所有数据均经过了符合国家数据管理规范的脱敏处理，且不涉及用户的个人敏感信息。
        多媒介渠道投放提高用户触达率。依托庞大的数据基础和人工智能算法，依托合作互联网平台的覆盖终端，数鸣科技能够梳理出对特定产品具有强需求的人群，并通过信息终端、私域流量平台和三大运营商渠道精准投放，做到广覆盖、高触达、高转化。
        SAAS云端服务平台让运营环节降本增效。针对大型客户需要成套解决方案以及短平快实施的需求，数鸣科技将核心算法能力与数据整合能力及渠道触达能力，进行有效融合，推出聚合了大数据处理、联邦建模、营销分发、数据监控等平台功能的SAAS云端服务平台，将技术和系统服务进行标准产品化输出。
        SAAS平台能够完美契合用户不同的投放规模和精准触达需求，提升不同行业间方案可复制性，且对信息进行加密及监控保证数据安全。目前，这一平台已有二十余家健康领域的服务对象。
      </div>
      <div class="margintop50 w30 margincent textcent subhead w60 fontsize25">
        “先服务，后给钱”的商业模式获客户高度认可
      </div>
      <div class="margintop50 textIndent">
        项亮以健康保险营销为例，对数鸣科技的业务进行了介绍。
        数鸣科技对健康保险企业提供短期营销和长期运营服务，不仅帮助险企解决获客难题，也能够帮助其运营私域流量，进行流量用户价值增值。
        中国人口基数大，保险市场广。但“非刚需”是所有保险产品的痛点。保险姓“保”就在于“保障”的功能上，健康人群不能切实感受到保险产品的重要性，具有真实保险需求的群体占比并不大。数鸣科技便是帮助险企梳理出对特定产品具有真需求的客户群体作为营销对象，扩大险企业务范围。
        首先，数鸣科技要了解险企营销需求、技术能力和数据情况，提出保险行业最佳实践方案，并根据险企产品设计营销策略。
        其次，制定10组不同的测试方案，小批量部署测试每组营销触达，通过人工智能算法分析筛选最优方案，在测试成功的基础上加大投放规模，并实时监测不同画像客户群点击、转化等指标并根据人工智能算法优化投放策略。
        值得一提的是，数鸣科技采用的是按效果付费结算的商业模式。而这一“先服务后给钱”的市场模式也经历了市场的检验，数鸣科技的毛利率远高于行业均值。
        项亮介绍，数鸣科技敢于将自身成本前置，不仅是对合作伙伴的信任，也是对于自身具备极强业务能力的自信。
        “实用是第一要义。任何的技术、能力都要为社会创造出真实的价值。”据了解，数鸣科技从公司设立初期完成天使轮融资后，便一直处于盈利状态。目前，数鸣科技已完全覆盖健康险头部企业。
        展望未来，数鸣科技会基于自身四大优势，继续深耕保险领域，并逐渐向互联网医院、健康管理板块延伸，力图为整个大健康领域企业提供标准化的系统服务，与客户共同成长。另外，数鸣科技也会继续对技术进行更新迭代，吸引更多人才。
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.minwidth {
  min-width: 1000px;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.w70 {
  width: 70%;
}

.w60 {
  width: 60%;
}

.w30 {
  width: 40%;
}

.margincent {
  margin: 0 auto;
}

.margintop50 {
  margin-top: 50px;
}

.marginbot40 {
  margin-bottom: 40px;
}

.fontsize30 {
  font-size: 30px;
}

.fontsize25 {
  font-size: 25px;
}

.textcent {
  text-align: center;
}

.subhead {
  border-bottom: 3px solid rgb(255, 0, 0);
}

.positionrel {
  position: relative;

  height: 440px;
  overflow: hidden;
}

.positionabs {
  position: absolute;
  left: 50%;
  transform: translate(-50%);

  width: 1920px;
}

.textIndent {
  text-indent: 32px;
  text-align: justify;
  line-height: 30px;
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0px;
  }

  .mw100 {
    width: 100vw;
  }

  .flexnone {
    display: block;
  }

  .w60 {
    width: 60%;
  }

  .positionrel {
    position: relative;

    height: 179px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    
    width: 780px;
  }

  .mw90 {
    width: 90%
  }
}
</style>
<template>
  <div class="minwidth w100">
    <div>
      <tabbar></tabbar>
    </div>

    <div class="positionrel w100">
      <img :src="require('../../public/img/ai/pic1.png')" class="positionabs" />
    </div>
    <div class="w100 margintop50 marginbot40">
      <div class="margincent w80 textcent fontsize50 margintop50 fontsize30">AI算法</div>
      <div class="margincent w80 mw100">
        <img :src="require('../../public/img/ai/pic2.png')" class="w100" />
      </div>
      <div class="margincent w80 mw100">
        <img :src="require('../../public/img/ai/pic3.png')" class="w100" />
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.w100 {
  width: 100%;
}

.w80 {
  width: 80%;
}

.minwidth {
  min-width: 1000px;
}

.margintop50 {
  margin-top: 50px;
}

.margintop70 {
  margin-top: 70px;
}

.marginbot40{
  margin-bottom: 40px;
}

.textcent {
  text-align: center;
}

.fontsize50 {
  font-size: 50px;
}

.margincent {
  margin: 0 auto;
}

.positionrel {
  position: relative;

  height: 600px;
  overflow: hidden;
}

.positionabs {
  position: absolute;
  left: 50%;
  transform: translate(-50%);

  width: 1920px;
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0px;
  }

  .mw100 {
    width: 100vw;
  }

  .flexnone {
    display: block;
  }

  .fontsize30 {
    font-size: 30px;
  }

  .positionrel {
    position: relative;

    height: 179px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 780px;
  }
}
</style>
<template>
  <div class="minwidth w100 mw100">
    <div>
      <tabbar></tabbar>
    </div>

    <div class="positionrel w100">
      <img
        :src="require('../../public/img/join/pic1.png')"
        class="positionabs"
      />
    </div>

    <div class="w60 margincent margintop70 marginbot70 minwidth w80">
      <div class="fontsize30 textcent" style="color: #bc9158;">
        {{ msglist[num].job }}
      </div>
      <div class="fontsize16 margintop70">
        <div> 职位描述：</div>
        <div v-for="(item, index) in msglist[num].msg" :key="index" class="textalign_justify">
          {{ item }}
        </div>
        
      </div>
      <div class="fontsize16 margintop50">
        <div>任职资格：</div>
        <div v-for="(item, index) in msglist[num].ask" :key="index" class="textalign_justify">
          {{ item }}
        </div>
        
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msglist: [
        {
          job: "高级数据分析师",
          msg:["1. 为产品运营团队提供数据分析和用户与产品的推荐数据支持；","2. 负责整理各业务数据，对多种数据源的进行深度诊断性组合分析、挖掘深度分析和建模，对相关数据进行深度挖掘分析；","3. 参与实际的推荐营销业务，通过分析、建模用户的各项特征数据，构建规则、模型来挖掘营销产品最相关的用户群；","4. 深入理解业务，发现业务特征潜在机会，并给出有效的行动建议；","5. 对公司业务的运营进行评估和建议，从数据的角度推动公司运营决策、产品方向和运营。"],
          ask:["1. 本科、计算机、统计和数学等相关专业；985/211优先；","2. 熟练使用SQL语言进行数据分析工作；","3. 除Excel外，精通一门数据分析工具，熟悉hive数据库结构，python者优先；","4. 具备良好的沟通能力和表达能力，有独立开展业务调研、数据分析、报告编写的经验，对解决具有挑战性问题充满激情。"]
        },
        // {
        //   job: "商务经理",
        //   msg: "职位描述：1、负责公司合作机构客户沟通，获取客户在大数据方面的需求，进行公司平台产品的业务拓展；2、通过对业务数据的分析,结合合同运营的实际情况,洞察业务问题并反馈优化建议；3、监控项目的整体执行情况,控制合同的商务风险,对相关业务数据进行准确判断；",
        //   ask: "任职要求：1、本科以上学历；2、熟悉商务运营、商务谈判,具有较好的商务沟通能力；3、富有活力和激情，为人开朗热情，勇于承担压力，对项目及工作有责任心；4、具有较强的沟通及协调能力,有良好的协作与团队精神；",
        // },
        {
          job: "推荐算法工程师",
          msg: ["1.基于用户和行为数据，建立推荐算法与策略；","2.梳理业务逻辑，流程，数据；","3.对用户行为数据进行分析，挖掘，实现个性化推荐、精准推荐算法；"],
          ask: ["1. 计算机、数学、统计相关专业本科以上学历；","2. 至少熟悉一门编程语言(python, java)，具有扎实的代码功底和实战能力；","3. 熟悉机器学习、深度学习相关算法，具备优秀的数据结构认识，算法和编程能力；","4. 有至少1年推荐系统实际项目经验，具备用户画像、个性化推荐方面的实际工作经验；","5. 熟悉主流大数据处理技术（Hadoop/Spark/HBase等）；"]
        },
        // {
        //   job: "高级前端工程师",
        //   msg: "职位描述：1.根据产品需求和原型，完成页面设计及前段功能开发；2.前后端接口对接，完成数据交互；3.参与需求评审、项目排期、架构设计和编码；4.遵循代码规范，保证交付代码质量；5.持续不断优化代码；6.兼容性问题处理；7.前端性能优化；",
        //   ask: "任职要求：1.具备商城系统C端或商城开发项目经验，技术水平要求为中高级以上，有微信小程序、H5开发经验者优先；2.精通H5、CSS3、Javascript、jQuery等网页前段基础技术，具有良好的Javascript代码规范，熟练掌握JS面向对象的编程思想，继承，原型，闭包的使用；3.掌握Vue前端框架技术，并运用于项目；4.熟悉HTML5特性，熟练运用html5特性构建移动端webAPP，掌握webAPP的整体实现方式；5.具备数据处理及开发能力，具备与后端数据交互相关的开发能力；6.精通浏览器兼容性、web优化技巧、手机端兼容性；",
        // },
      ],

      num: 0,
    };
  },

  methods: {},

  mounted() {
    this.num = this.$route.query.num;
  },
};
</script>

<style scoped>
.w100 {
  width: 100%;
}

.w60 {
  width: 60%;
}

.minwidth {
  min-width: 1000px;
}

.flex {
  display: flex;
}

.textcent {
  text-align: center;
}

.textalign_justify {
  text-align: justify;
  line-height: 25px;
}

.fontsize50 {
  font-size: 50px;
}

.fontsize30 {
  font-size: 30px;
}

.fontsize20 {
  font-size: 20px;
}

.fontsize16 {
  font-size: 16px;
}

.fontsize14 {
  font-size: 14px;
}

.fontsize12 {
  font-size: 12px;
}

.fontcolorb0 {
  color: #b0b0b0;
}

.margincent {
  margin: 0 auto;
}

.margincentY {
  margin: auto 0;
}

.margintop50 {
  margin-top: 50px;
}

.margintop70 {
  margin-top: 70px;
}

.marginleft20 {
  margin-left: 20px;
}

.marginbot70 {
  margin-bottom: 70px;
}

#job:hover {
  color: #bc9158;
}

.positionrel {
  position: relative;

  height: 440px;
  overflow: hidden;
}

.positionabs {
  position: absolute;
  left: 50%;
  transform: translate(-50%);

  width: 1920px;
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0px;
  }

  .mw100 {
    width: 100vw;
  }

  .flexnone {
    display: block;
  }

  .w80 {
    width: 80%;
  }

  .positionrel {
    position: relative;

    height: 179px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 780px;
  }
}
</style>
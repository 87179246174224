<template>
  <div class="minwidth w100 mw100">
    <tabbar></tabbar>

    <div class="positionrel w100">
      <img :src="require('../../public/img/join/pic1.png')" class="positionabs" />
      <div style="color: #fff;font-size: 3rem;position: absolute;top: 8rem;left: 50%;transform: translate(-50%);">
        行业资讯
      </div>
    </div>

    <div v-if="num == 0" style="width: 60%;margin: 0 auto;padding-bottom: 2rem;">
      <div
        style="width: 100%;font-size: 2rem;border-bottom: 0.1rem solid #ebecea;line-height: 4rem;margin-top: 2rem;">
        5G-A护航低空经济背后，中国移动的“算”与“谋”
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        通信世界网消息（CWW）低空经济作为国家战略性新兴产业，又是新质生产力的典型代表，正成为拉动新一轮经济增长的重要引擎。尤其是今年步入5G-A商用元年以来，伴随着5G-A技术的创新应用与持续突破，低空经济正迎来前所未有的发展机遇。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        通信世界网消息（CWW）低空经济作为国家战略性新兴产业，又是新质生产力的典型代表，正成为拉动新一轮经济增长的重要引擎。尤其是今年步入5G-A商用元年以来，伴随着5G-A技术的创新应用与持续突破，低空经济正迎来前所未有的发展机遇。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        在探索5G-A护航低空经济这一进程中，电信运营商的角色也正从网络基础设施的提供者转变为低空经济的筑路先锋。近日，在2024中国信息通信大会暨低空智联与监管技术创新发展论坛上，中国移动（成都）产业研究院（以下简称“中国移动成研院”）领导与专家交流了当前中国移动基于5G-A进行低空智联与监管研究的最新进展，分享其低空智联网建设成果的同时，展现了其锚定低空经济这一新引擎，意在领航数字经济新未来的“算”与“谋”。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        定位锚点：做好低空智联网的构建者与运营者
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        从今年全国两会，“低空经济”首次被写入政府工作报告，到全国各地方政府陆续出台支持政策。截至2024年5月，31个省（区、市）已写入政府工作报告，发布253条低空经济相关规范和鼓励性政策。围绕低空空域开放、产业规范化发展、产业创新应用等层面，各地正推动低空经济从探索走向发展。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        整体来看，受政策、市场需求与5G-A通感一体技术的合力推动，低空经济正处于迅猛发展阶段。根据摩根士丹利数据，预计未来低空经济的市场规模将达到1.5万亿美元。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        聚焦网络建设进展，随着低空经济的“四张网”建设，业内多位院士提出“低空智联网”，国务院亦将其作为低空开放和低空经济发展的重要信息基础设施。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        所谓低空智联网，是指以空天地多网融合（低轨卫星、ADS-B、蜂窝网络和地面网络等）、网络化感知、计算服务为核心，构建的支撑低空网络化、数字化、智能化、服务化综合系统，实现高密度、大流量、多用户等场景下的通信、导航、监视和服务的统一。其也是低空经济的核心基础设施。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        在已有网络基础设施建设优势的基础上，中国移动也看准了发展新机遇，认识到其战略价值体现在低空智联网和监管服务领域，并逐步探索开展涵盖5G-A网络、终端、业务平台、通信测控、数据传输等应用场景和相应业务。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        就无人机来看，由于每个无人机生产厂商都使用自己私有的自建通信与控制链路（数据链路）来控制无人机飞行，飞行和控制距离受限，无法进行超视距的远程飞行控制和实时信息交互。对此，中国移动认为5G网联是解决上述难题的有效方法和手段。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        面对空域目标感知与监管领域存在的探测范围受限、感知精度难保证、黑飞行为难监管、航空安全难保、身份认证互信难等困扰，中国移动提出了基于5G探测感知的方案来解决上述难题。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        研判行业痛点，讲求精准施策。相对应地，中国移动在业界首次给出5G网联无人机的定义，业界首次实现用5G移动通信蜂窝网络替代传统无人机自建通信和控制链路（C2链路），并完成对无人机的超视距远程飞行控制，以及交互信息的大带宽、高速率、低时延的实时传输处理，开展了一系列基于5G的无人机通信与感知技术研究。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        目前，中国移动已自主研发打造了“1+1+3+3+3+N”核心产品和能力体系，积极融合行业构筑解决方案和品牌影响。布局云、网、端产品，锻造差异化优势，中国移动自主研发了中移凌云、哈勃一号、中移应龙、空中信号测量仪等核心产品，开放能力对接大疆、中航工业等30余家头部品牌，涉及100多个主流机型。在智能网联能力、产学研团队、标准与专利核心卡位、行业生态整合能力等方面积累了先发优势。
      </div>
      <div style="width: 100%;background-color: #ebecea;text-align: center;border-radius: 1rem;margin-top: 2rem;">
        <img style="width: 95%;" :src="require('../../public/img/news/news0.png')" alt="">
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        目前，中国移动基于已有核心技术，空对地连续覆盖技术成为国际国内首创且对地覆盖范围业界最大；面向低慢小无人机目标的探测感知技术国际领先；基于5G通感的安全监管平台（中移凌云）国际先进。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        据了解，目前中国移动核心技术创新点主要体现在4个方面。即面向无人机飞行空域的5G立体移动通信覆盖技术、中移凌云管理运营平台、无人机机载可信网联终端以及基于光学阵列的通感一体增强感知技术。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        值得一提的是，助力低空智联与监管服务，中国移动构筑了以中移凌云平台为底座的低空监管系统，可实现对合作目标和非合作目标的管理。在这个过程中，中国移动也锻造形成了六大核心能力。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        一是低空空域智慧规划。中国移动成研院自主研发面向低空空域运营管理的中移凌云平台功能，通过构筑低空四维时空（经度、纬度、高度、时间）体系，在空域划设、空域评估、空域动态配置、空域数据管理等方向开展技术研究和产品功能研发，赋能低空监管运营者。同时，创新性制定国家标准《民用无人驾驶航空器系统身份识别三维空间位置标识编码》，为规范空域管理提出了有效方法手段。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        二是5G-A网络实现无人机可信接入。中国移动推动无人机可信接入方案成为3GPP标准，已在R17冻结，实现了核心网对无人机UAVID标识的识别及其增强服务，并实现了三项业界首次。业界首次完成终端侧新型无人机标识的引入及能力适配，首次在网络侧通过升级支持UAS网络功能完成无人机可信标识的识别、映射、鉴权授权及全生命周期管理，首次在飞控平台侧完成与核心网能力开放平台的对接、实现标识与运营信息的绑定。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        三是基于5G-A的无人机全生命周期管理。中国移动借鉴手机、车辆等成熟的管理模式，构建基于空天地一体化网络的无人机全生命周期管理系统。依据我国首部无人驾驶航空器专项行政法规《无人驾驶航空器飞行管理暂行条例》，中国移动可面向监管提供规范、标准、可靠的技术手段，保持低时延的双向通信，实现对无人机看得见、叫得到、管得住。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        四是基于5G-A的无人机低空探测和反制。当前，国家亟需打造一张面向无人机飞行空域的感知监管与通信网络，为低空安全提供能力保障。中国移动基于5G基站无人机探测结果，在中国移动自研的“中移凌云”平台进行实时显示，相比传统方案优势明显。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        五是赋能低空经济的智慧运营服务。围绕低空经济场景，面向无人驾驶航空器运营用户，中移凌云通信与感知平台可提供365天×24h全天候飞行安全监管、智能偏航预警、智能航线规划、多源数据融合与反制等能力，为无人机物流配送、eVTOL载人飞行、无人机巡检及安防保障等领域提供数据传输、数据分析等能力，助力低空经济发展。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        六是应用通信服务能力。针对各类场景下应急通信保障的任务需求，中国移动构建了四纵三横的多维度无缝应急通信体系。选配大型、中型、系留和无人直升机等多类型飞行平台，搭载中移应龙航空应急专用基站，结合无人机应急管理平台，实现在地震、洪涝、火灾、近海等场景下的立体应急通信保障。在国家应急管理部科技和信息化司主办的应急救援无人机高海拔地区“三断”场景测试验证中，2023年中国移动成为唯一完成实战验证并获得验证证书的运营商。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        拓展国际市场合作。中国移动积极构筑国内外行业影响，目前其5G-A低空智联与监管成果被多位院士、专家评定为项目研制难度大、系统复杂、创新性强、核心关键技术自主可控，总体技术达到国际先进水平，其中研制的无人机机载专用终端属重大创新，空对地信道建模方法处于国际领先水平。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        此外，中国移动积极参与国际组织工作，牵头参与发布多项IEEE国际标准，并担任IEEE无人机应用与通信标委会秘书长单位以及担任GSMA无人机工作组联席主席单位。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        加强产业生态建设。一方面，中国移动积极推动产业标准制定，目前已牵头制定国际国内标准12项，申请国家/国际发明专利75项，发表SCI、EI学术论文50余篇，并获得IEEE最佳论文奖。不仅如此，技术成果获得权威认可。截至目前，已获得各级奖项20余项，其中国际级4项，国家级8项，省部级8项。
      </div>
      <div style="width: 100%;background-color: #ebecea;text-align: center;border-radius: 1rem;margin-top: 2rem;">
        <img style="width: 95%;" :src="require('../../public/img/news/news1.png')" alt="">
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        总而言之，中国移动在5G-A与低空经济领域具有明确的战略定位，在技术创新、网络建设与产业合作等方面均取得了显著的发展成果，并规划了宏伟的未来布局。随着5G-A 技术的不断发展和低空经济的持续升温，中国移动有望在这一领域发挥更大的作用，引领低空经济实现高质量发展，为全球通信与经济发展贡献更多力量。
      </div>
    </div>

    <div v-if="num == 1" style="width: 60%;margin: 0 auto;padding-bottom: 2rem;">
      <div
        style="width: 100%;font-size: 2rem;border-bottom: 0.1rem solid #ebecea;line-height: 4rem;margin-top: 2rem;">
        “死磕”小米？保时捷启用中国高管，本地研发本地采购
      </div>
      <div style="width: 100%;background-color: #ebecea;text-align: center;border-radius: 1rem;margin-top: 2rem;">
        <img style="width: 95%;" :src="require('../../public/img/news/news2.png')" alt="">
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        近日，保时捷官方宣布在中国设立技术部，这一重大举措标志着保时捷在中国市场的战略布局进一步深化，旨在充分利用中国在新能源汽车领域的领先优势，尤其是强大的供应链资源，推动其在中国市场的持续发展。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        保时捷作为全球知名的豪华汽车品牌，一直以来在中国市场备受关注。然而，近期其市场表现却面临一些挑战。根据 2024 年一季度财报数据显示，今年一季度保时捷在全球共交付了 7.76 万辆新车，同比下滑 3.9%。而中国市场作为其最大的单一市场，销量表现尤为惨淡，2023 年一季度销量为 21365 辆，到了 2024 年同期，销量降至 16340 辆，同比降幅近 25%，远超全球市场跌幅。营收方面，保时捷一季度总体营收达到 90 亿欧元，同比下降 10.8%，其中新车销售额为 81 亿欧元，同比下降 12.7%。受销量下跌和价格下探的双重影响，汽车业务毛利为 23.4%，较去年同期下降了 30.3%，销量利润率也降至 14.2%，同比减少 4 个百分点。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        今年第三季度的财报也显示出一些情况，1-9 月份，保时捷在中国市场累计销量 4.33 万辆，同比下降了 29%，其中保时捷帕拉梅拉和 Taycan 的跌幅尤为显著，分别达到了 20% 和 50%。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;;text-align: justify;">
        保时捷在中国市场遭遇困境的原因，一方面是随着中国汽车市场的逐渐成熟和消费者对汽车品质要求的日益提高，保时捷等传统豪华品牌面临着来自新能源汽车等新兴势力的挑战；另一方面，其自身在产品更新和市场营销方面也存在一定问题。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        不仅如此，中国新能源崛起也给保时捷带来了一定的影响。如小米汽车首款车型小米 SU7 因为外形有保时捷 Taycan 的神韵，而被戏称为“米时捷”。小米 SU7 自打出现，驾驶机械性上对标保时捷 Taycan Turbo，在科技和生态方面对标特斯拉 Model S。其近 5 米的车长和 3 米的轴距，采用双电机全轮驱动，最大马力 673PS，峰值功率为 495kw，峰值扭矩为 838N · m，零百加速时间为 2.78 秒，CLTC 续航里程 800km，这对于冲着保时捷赛道属性的用户来说可能会有所动摇。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        并且小米 SU7 凭借破圈的热度，取代保时捷，成为不少微商、医美等行业人士吸引关注和进行业绩背书的法宝。据知名行业咨询公司杰兰路对保时捷内部人士的一份访谈，保时捷客户中约 60%-70% 是真正将保时捷当做消费品的高净值人群，其余的 30%-40% 主要将保时捷视为进入上层社会的入场券。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        与此同时，在百万级性能车的赛道上，小米也成为了保时捷的直接竞品。预售价 81.49 万元的小米 SU7 Ultra，预订开启 10 分钟收获 3680 份订单。能够花近百万买一辆小米的用户，自然也是保时捷 718、911 两大跑车产品线的目标客户。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        随着中国成为全球新能源汽车的领军者，众多国际汽车品牌纷纷加大在中国的投入。保时捷也敏锐地察觉到这一趋势，积极响应市场变化。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        在 2024 广州车展上，保时捷也带来了诸多亮点车型。11 月 15 日，新款保时捷 911 正式亮相，包括融合了 turbo-hybrid 技术的新款保时捷 911 Targa 4 GTS、追求极致轻量化设计的新款 911 Carrera T，以及为纪念保时捷 911 Turbo 诞生 50 周年而特别推出的限量版车型。其中，保时捷 911 Turbo 50 周年纪念车型迎来了国内首秀，全球限量 1974 辆，以此纪念第一台 911 Turbo 的首次亮相年份，国内已开启预售，指导价为 267.8 万元起 。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        此外，全新纯电动 Macan 长续航版与专为中国市场量身定制的新款 Taycan4 也登陆了车展展台。全新 Panamera 与新款 Cayenne 也在车展上亮相，其中 Panamera 车型率先搭载了以 Porsche Active Ride 为代表的诸多创新科技加持底盘系统，新款 Cayenne 则凭借以驾驶者为中心的座舱设计、全新的底盘技术等，再次捍卫了其细分市场运动标杆的主张 。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        此次保时捷设立的技术部不仅负责研发工作，还涵盖本地采购与质量保证职能，全方位统筹保时捷在中国的业务发展。李楠出任保时捷中国技术部副总裁，他此前在梅赛德斯 - 奔驰担任高级职务，在信息娱乐、大数据和人工智能等领域积累了深厚经验。这将为保时捷在中国市场深入了解客户需求、开发符合中国消费者喜好的产品提供有力支持。在加入保时捷后的前六个月，李楠一直在德国魏斯阿赫研发中心工作，向保时捷全球执行董事会成员、车辆 IT 部门负责人 Sajjad Khan 汇报，积极与各跨领域团队协作，确保上海的研发工作与总部实现无缝对接，促进知识的顺畅传递和协同效应的产生。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        Sajjad Khan 对李楠的能力给予高度评价，认为他丰富的市场经验和技术专长将助力保时捷在中国战略性地拓展车辆研发，其在电气 / 电子和软件开发领域的知识有助于深入理解中国客户的多样化需求。保时捷中国总裁及首席执行官潘励驰也强调，李楠作为本土人士，其洞察和专业能力将加速保时捷在车辆互联和智驾等方面的产品升级，再次彰显了保时捷对中国市场的长期承诺。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        保时捷的举措，是其积极适应市场变化、深入本土化战略的关键一步。这不仅有助于保时捷更好地满足中国消费者的需求，提升产品竞争力，还将进一步加强其与中国供应链的合作，实现成本优化和可持续发展。未来，保时捷有望凭借这一战略布局在中国市场取得更为出色的成绩，为中国消费者带来更多创新、优质的产品和服务，同时也为其他国际汽车品牌在中国的发展提供有益借鉴。业界也将密切关注保时捷在中国市场的后续动态，期待其在本土化战略的推动下不断创新和突破。
      </div>
    </div>


    <div v-if="num == 2" style="width: 60%;margin: 0 auto;padding-bottom: 2rem;">
      <div
        style="width: 100%;font-size: 2rem;border-bottom: 0.1rem solid #ebecea;line-height: 4rem;margin-top: 2rem;">
        中国联通上涨2.31%，报5.32元/股
      </div>
      <div style="width: 100%;background-color: #ebecea;text-align: center;border-radius: 1rem;margin-top: 2rem;">
        <img style="width: 95%;" :src="require('../../public/img/news/news4.png')" alt="">
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        12月9日，中国联通盘中上涨2.31%，截至09:54，报5.32元/股，成交8.49亿元，换手率0.52%，总市值1691.79亿元。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        资料显示，中国联合网络通信股份有限公司位于北京市西城区金融大街21号4楼，公司主要业务为提供联网通信和算网数智服务，设有覆盖全国及境外的现代通信网络，服务于党政军系统、各行各业及广大人民群众，致力于技术领先、高度集成的“全覆盖、全在线、全云化、绿色化、一站式”数字化服务。关键经营数据方面，公司在2024年《财富》世界500强中位列第279位，持有控股的联通BVI公司间接持有联通红筹公司43.9%的股份，拥有实际控制权。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        截至9月30日，中国联通股东户数49.65万，人均流通股6.24万股。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;;text-align: justify;">
        2024年1月-9月，中国联通实现营业收入2901.23亿元，同比增长2.99%；归属净利润83.38亿元，同比增长10.04%。
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        num: 0,
      };
    },

    methods: {},

    mounted() {
      this.num = this.$route.query.num;
    },
  };
</script>

<style scoped>
  .w100 {
    width: 100%;
  }

  .w60 {
    width: 60%;
  }

  .minwidth {
    min-width: 1000px;
  }

  .flex {
    display: flex;
  }

  .textcent {
    text-align: center;
  }

  .textalign_justify {
    text-align: justify;
    line-height: 25px;
  }

  .fontsize50 {
    font-size: 50px;
  }

  .fontsize30 {
    font-size: 30px;
  }

  .fontsize20 {
    font-size: 20px;
  }

  .fontsize16 {
    font-size: 16px;
  }

  .fontsize14 {
    font-size: 14px;
  }

  .fontsize12 {
    font-size: 12px;
  }

  .fontcolorb0 {
    color: #b0b0b0;
  }

  .margincent {
    margin: 0 auto;
  }

  .margincentY {
    margin: auto 0;
  }

  .margintop50 {
    margin-top: 50px;
  }

  .margintop70 {
    margin-top: 70px;
  }

  .marginleft20 {
    margin-left: 20px;
  }

  .marginbot70 {
    margin-bottom: 70px;
  }

  #job:hover {
    color: #bc9158;
  }

  .positionrel {
    position: relative;
    height: 340px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 1920px;
    -webkit-filter: contrast(50%);
    filter: contrast(50%);
  }

  @media screen and (max-width: 750px) {
    .minwidth {
      min-width: 0px;
    }

    .mw100 {
      width: 100vw;
    }

    .flexnone {
      display: block;
    }

    .w80 {
      width: 80%;
    }

    .positionrel {
      position: relative;

      height: 179px;
      overflow: hidden;
    }

    .positionabs {
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      width: 780px;
    }
  }
</style>
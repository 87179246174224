<template>
  <div class="" style="position: fixed;right: 0;bottom: 5rem;cursor: pointer;">
    <img style="width: 5rem;" :src="logo" alt="" @click="bottomSkip">
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../../public/img/main/kefu.png")
    };
  },

  methods: {
    skip(url) {
      this.$router.push(url);
    },
    bottomSkip(){
      location.href='https://work.weixin.qq.com/kfid/kfca577238c56c44315'
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #fff;
}

.bg {
  background-color: #343434;
}

.font16 {
  color: #ffffff;
  font-size: 16px;
}

.fontmsg {
  color: #75767a;
}

.textcent {
  text-align: center;
}

.icon {
  width: 10px;
}

.flex {
  display: flex;
}

.flex-jcbet {
  justify-content: space-between;
}

.w100 {
  width: 100%;
}

.w60 {
  width: 60%;
}

.minwidth {
  min-width: 1000px;
}

.margincenter {
  margin: 0 auto;
}

.margincentY {
  margin: auto 0;
}

.margintop10 {
  margin-top: 10px;
}

.margintop20 {
  margin-top: 20px;
}

.marginleft {
  margin-left: 10px;
}

.paddingtop20 {
  padding-top: 20px;
}

.logoblack {
  height: 150px;
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0px;
  }

  .flexnone {
    display: block;
  }

  .mw100 {
    width: 100vw;
  }

  .fontmsg {
    font-size: 12px;
  }

  .font12 {
    font-size: 12px;
  }

  .mfont14 {
    font-size: 14px
  }

  .logoblack {
    height: 80px;
  }

  .mmargintop20 {
    margin-top: 20px;
  }
}
</style>
<template>
  <div style="position: relative;" class="w100 minwidth mw100">
    <tabbar></tabbar>

    <!-- <div>
      <carousel style="position: relative;z-index: 0; width: 100%"></carousel>
    </div> -->


    <div style="width: 100%;position: relative;">
      <img style="width: 100%;height: auto;" :src="require('../../public/img/main/picMain.png')" alt="">
      <div
        style="position: absolute;top: 70%;left: 50%;transform: translate(-50%);width: 30%;height: 5rem;line-height: 5rem;text-align: center;border: 4px solid transparent;border-radius: 16px;cursor: pointer;background-clip: padding-box, border-box;background-origin: padding-box, border-box;background-image: linear-gradient(to right, #fff, #fff), linear-gradient(180deg, #8F41E9, #578AEF);color: #5F80EA;letter-spacing: 1rem;font-size: 2rem;font-weight: bold;"
        @click="gotoJoin">
        获取解决方案
      </div>
      <!-- <div
        style="position: absolute;top: 60%;left: 50%;transform: translate(-50%);width: 27%;height: 5rem;line-height: 5rem;text-align: center;border: 4px solid transparent;border-radius: 16px;cursor: pointer;"
        @click="gotoJoin"></div> -->
    </div>


    <div style="width: 80%;margin: 0 auto;margin-top: 3rem;">
      <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;text-align: center;">关于我们</div>
      <div style="font-size: 1rem;color: #999999;text-align: center;">ABOUT US</div>
      <div
        style="margin-top: 1rem;font-size: 1.2rem;line-height: 2.6rem;text-align: justify;background-color: #f8fafc;padding: 2rem;border-radius: 1rem;letter-spacing: 0.1rem;">
        ● 哲喆科技成立于2022年3月，是一家专注于数字增值产品服务的创新型科技企业。我们深耕互联网及通信领域，服务对象涵盖互联网企业、通信行业及三大运营商（联通、电信、移动），致力于为客户提供一体化、专业化的解决方案。<br>
        ●核心业务涵盖产品策划、广告投放、大数据中台构建及内容服务，帮助客户实现业务增值与GMV增长。依托精准的数据分析、领先的技术能力及深厚的行业积累，我们为客户提供贯穿全链路的解决方案，以推动数字生态体系的构建与优化。<br>
        ● 哲喆科技汇聚了一支由行业专家组成的高素质团队，拥有丰富的实战经验和技术实力。我们秉承“以客户为中心、以创新为驱动”的理念，通过卓越的服务和领先的技术助力客户应对市场挑战。<br>
        ● 我们的愿景是成为广告投放与数字增值产品领域的引领者，与客户建立长期互信的合作伙伴关系，共同推动互联网广告行业的高质量发展。<br>
        <div
          style="text-align: right;font-size: 1.2rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
          @click="gotoPartner">
          了解更多 >
        </div>
      </div>
    </div>

    <div style="width: 80%;margin: 0 auto;margin-top: 5rem;">
      <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;text-align: center;">我们的优势</div>
      <div style="font-size: 1rem;color: #999999;text-align: center;">OUR ADVANTAGES</div>
    </div>
    <div
      style="width: 75%;margin: 0 auto;margin-top: 1rem;font-size: 1.2rem;line-height: 2.6rem;text-align: justify;background-color: #f8fafc;padding: 2rem;border-radius: 1rem;letter-spacing: 0.1rem;">
      <div style="text-indent: 2rem;">
        以智能化为核心，哲喆科技联接海量数字商品品牌与运营内容，通过优化迭代商业模式与技术策略，为合作伙伴提供高性价比的数字商品增值服务。我们致力于为消费者创造丰富、便捷、优质的数字消费体验，以推动行业价值链的持续升级。
      </div>
      <div style="text-indent: 2rem;">
        我们以创新驱动、数据赋能，构建涵盖运营、权益、数据及服务的全维度价值体系。通过深度聚焦行业需求，我们致力于与合作伙伴共创可持续发展的商业未来。
      </div>
    </div>
    <div style="display: flex;align-items: center;justify-content: center;width: 80%;margin: 0 auto;margin-top: 3rem;"
      class="mw100">
      <div v-for="(item,index) in typeList" style="text-align: center;cursor: pointer;" class="w100"
        @click="typeIndex = index">
        <img style="flex: 0.25;width: 20%;" :src="index == typeIndex ? item.urlCheck : item.urlUncheck">
        <div style="color: #999999;font-size: 1.3rem;margin-top: 1rem;"
          :style="index == typeIndex ? 'color: #259FFF;':''">
          {{ item.text }}</div>
      </div>
    </div>
    <div class="w100 mw100">
      <div
        style="display: flex;width: 70%;margin: 0 auto;justify-content: space-between;align-items: center;background-color: #f8fafc;margin-top: 3vw;">
        <div style="width: 55%;padding: 2rem;letter-spacing: 0.1rem;">
          <div style="margin-top: 1rem;line-height: 2rem;text-align: justify;">{{ typebtmList[typeIndex].textTop }}
          </div>
          <div style="margin-top: 3rem;line-height: 2rem;text-align: justify;">{{ typebtmList[typeIndex].textBtm }}
          </div>
        </div>
        <img style="width: 40%;" :src="typebtmList[typeIndex].textBg" alt="">
      </div>
    </div>

    <div style="width: 80%;margin: 0 auto;margin-top: 5rem;">
      <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;text-align: center;">核心业务</div>
      <div style="font-size: 1rem;color: #999999;text-align: center;">CORE BUSINESS</div>
    </div>
    <div style="margin-top: 2rem;color: #555555;margin-bottom: 3rem;">
      <div
        style="width: 80%;margin: 0 auto;letter-spacing: 0.1rem;font-weight: bolder;display: flex;justify-content: flex-start;align-items: center;"
        @click="gotoJoin">
        <!-- <div style="font-size: 3rem;color: #06d1ed;">
          Ⅰ
        </div> -->
        <div>
          <div style="font-size: 1.5rem;">定制智能化运营服务</div>
          <div style="font-size: 1.2rem;">INTELLIGENT OPERATION SERVICE</div>
        </div>
      </div>
      <div style="width: 80%;margin: 0 auto;text-align: justify;line-height: 2rem;font-size: 1.2rem;margin-top: 1rem;">
        <div style="text-indent: 2rem;">
          哲喆科技为需推广量身定制“智能化运营”服务，通过前沿科技赋能，结合人工智能与在线客服系统，实现全天候24小时智能化响应，驱动广告行业的全面数字化升级。
        </div>
        <div style="text-indent: 2rem;">
          我们拥有一支专业化的运营团队，提供全周期运营支持，涵盖精准的场景化营销方案策划、技术研发、活动落地执行、数据监控与迭代优化等关键环节。通过创新驱动，我们致力于打造高品质的营销内容和大型特色IP活动，全面覆盖引流、获客、促活、留存、对账等高频应用场景，以确保营销效果最大化。
        </div>
        <div style="text-indent: 2rem;">
          哲喆科技整合优质品牌资源，深度融合会员体系、优惠券、支付立减、积分兑换等多维营销策略，打造创新形式的H5短剧、特色专区、节庆活动等媒介内容。通过故事化与礼品化的互动方式，强化客户情感联结，增强品牌认同感与用户粘性。
        </div>
        <div style="text-indent: 2rem;">
          我们构建了从私域营销到私域运营的闭环体系，帮助企业提升品牌热度与市场影响力，推动客户长期价值增长，并在激烈的市场竞争中确立稳固的行业领先地位。
        </div>
        <div
          style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
          @click="gotoTrends">
          查看详情 >
        </div>
      </div>
      <img style="width: 80%;display: block;margin: 0 auto;margin-top: 2rem;"
        :src="require('../../public/img/main/digitize.png')" alt="">
    </div>

    <div style="width: 80%;margin: 0 auto;margin-top: 5rem;">
      <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;text-align: center;">核心能力</div>
      <div style="font-size: 1rem;color: #999999;text-align: center;">CORE COMPETENCY</div>
    </div>

    <div style="margin-top: 2rem;color: #555555;">
      <div
        style="width: 80%;margin: 0 auto;letter-spacing: 0.1rem;font-weight: bolder;display: flex;justify-content: flex-start;align-items: center;"
        @click="gotoJoin">
        <!-- <div style="font-size: 3rem;color: #06d1ed;">
          Ⅰ
        </div> -->
        <div>
          <div style="font-size: 1.5rem;">定制化运营解决方案能力</div>
          <!-- <div style="font-size: 1.2rem;">INTELLIGENT OPERATION SERVICE</div> -->
        </div>
      </div>
      <div
        style="width: 80%;margin: 0 auto;text-align: justify;line-height: 2rem;font-size: 1.2rem;margin-top: 1rem;text-indent: 2rem;">
        我们的定制化运营解决方案通过集成先进的技术和精准的数据分析，全面提升运营效率、市场响应速度与客户满意度。无论是日常运营管理，还是精准营销与客户关系管理，我们的解决方案都能为企业提供持续的业务支撑，助力其实现长期、稳定的发展。
      </div>
      <div
        style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
        @click="gotoTrends">
        查看详情 >
      </div>
    </div>

    <div style="width: 80%;margin: 0 auto;margin-top: 2rem;display: flex;justify-content: space-between;">
      <!-- <div style="width: 49.5%;">
        <div style="width: 100%;">
          <img style="width: 100%;display: block;margin: 0 auto;margin-top: 0rem;"
            :src="require('../../public/img/main/block1_1.png')" alt="">
        </div>
        <div style="width: 100%;margin-top: 1rem;">
          <img style="width: 100%;display: block;margin: 0 auto;margin-top: 0rem;"
            :src="require('../../public/img/main/block2_1.png')" alt="">
        </div>
      </div>
      <div style="width: 49.5%;">
        <div style="width: 100%;">
          <img style="width: 100%;display: block;margin: 0 auto;margin-top: 0rem;"
            :src="require('../../public/img/main/block3_1.png')" alt="">
        </div>
        <div style="width: 100%;margin-top: 1rem;">
          <img style="width: 100%;display: block;margin: 0 auto;margin-top: 0rem;"
            :src="require('../../public/img/main/block4_1.png')" alt="">
        </div>
      </div> -->
      <img style="width: 100%;display: block;margin: 0 auto;margin-top: 0rem;"
        :src="require('../../public/img/main/block0.png')" alt="">
    </div>

    <div
      style="width: 100%;margin: 0 auto;background-color: #f8fafc;padding-top: 1rem;margin-top: 2rem;padding-bottom: 3rem;">
      <div style="width: 80%;margin: 0 auto;margin-top: 1rem;">
        <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;text-align: center;">内容服务</div>
        <div style="font-size: 1rem;color: #999999;text-align: center;">CONTENT SERVICES</div>
      </div>

      <div style="width: 80%;margin: 0 auto;font-size: 1.5rem;text-align: center;color: #313131;padding-top: 2rem;">
        定制化权益生态整合与分发解决方案
      </div>
      <div
        style="width: 80%;margin: 0 auto;font-size: 1rem;text-align: left;color: #666;margin-top: 1rem;line-height: 2rem;text-align: justify;">
        作为领先的权益生态整合与分发服务提供商，我们专注于通过技术驱动和资源整合，构建高效、精准、可持续的权益供应体系，致力于为用户和合作伙伴创造长期价值。我们深耕通信、消费、出行、娱乐等多个行业，通过智能化分发能力与个性化推荐技术，推动权益服务的创新与优化。<br>
        我们的定制化权益整合与分发解决方案通过精准的数据分析与智能分发技术，帮助用户享受便捷高效的权益服务，同时助力合作伙伴实现资源的最大化利用与商业价值提升。我们秉持创新驱动，推动整个权益服务行业的持续发展与升级。<br>
      </div>
      <div style="width: 80%;margin: 0 auto;margin-top: 2rem;">
        <img style="width: 100%;display: block;margin: 0 auto;" :src="require('../../public/img/main/ground1_1.png')"
          alt="">
      </div>
    </div>

    <!-- <div style="margin-top: 2rem;color: #555555;margin-bottom: 3rem;">
      <div
        style="width: 80%;margin: 0 auto;letter-spacing: 0.1rem;font-weight: bolder;display: flex;justify-content: flex-start;align-items: center;"
        @click="gotoJoin">
        <div>
          <div style="font-size: 1.5rem;">权益生态整合：跨行业资源整合与全场景覆盖</div>
        </div>
      </div>
      <div
        style="width: 80%;margin: 0 auto;text-align: justify;line-height: 2rem;font-size: 1.2rem;margin-top: 1rem;text-indent: 2rem;">
        我们通过打破行业壁垒，整合通信、消费、出行、娱乐等多个领域的优质资源，构建了一个跨行业、全场景的权益生态。我们的资源整合不仅为用户提供多样化的服务选择，还能帮助企业通过精细化管理提升资源利用效率，增强市场竞争力。
      </div>
    </div>
    <div style="margin-top: 2rem;color: #555555;margin-bottom: 3rem;">
      <div
        style="width: 80%;margin: 0 auto;letter-spacing: 0.1rem;font-weight: bolder;display: flex;justify-content: flex-start;align-items: center;"
        @click="gotoJoin">
        <div>
          <div style="font-size: 1.5rem;">智能化分发：精准、个性化的权益分发能力</div>
        </div>
      </div>
      <div
        style="width: 80%;margin: 0 auto;text-align: justify;line-height: 2rem;font-size: 1.2rem;margin-top: 1rem;text-indent: 2rem;">
        凭借领先的大数据分析与人工智能技术，我们为用户提供个性化的权益推荐。通过实时数据分析和智能算法，我们根据用户的行为、偏好与需求，精准推送适合的权益内容，提升用户体验和转化率。同时，我们确保各类资源的高效流转，最大化提升权益的分发效率。
      </div>
    </div>
    <div style="margin-top: 2rem;color: #555555;margin-bottom: 3rem;">
      <div
        style="width: 80%;margin: 0 auto;letter-spacing: 0.1rem;font-weight: bolder;display: flex;justify-content: flex-start;align-items: center;"
        @click="gotoJoin">
        <div>
          <div style="font-size: 1.5rem;">用户体验：高效便捷的权益获取与管理</div>
        </div>
      </div>
      <div
        style="width: 80%;margin: 0 auto;text-align: justify;line-height: 2rem;font-size: 1.2rem;margin-top: 1rem;text-indent: 2rem;">
        我们通过移动端应用及合作伙伴平台，提供便捷的权益获取通道，确保用户随时随地享受定制化的权益服务。通过持续的用户行为分析和反馈优化，我们不断提升推荐精度，为用户带来更高价值的体验。
      </div>
    </div>
    <div style="margin-top: 2rem;color: #555555;margin-bottom: 3rem;">
      <div
        style="width: 80%;margin: 0 auto;letter-spacing: 0.1rem;font-weight: bolder;display: flex;justify-content: flex-start;align-items: center;"
        @click="gotoJoin">
        <div>
          <div style="font-size: 1.5rem;">合作伙伴赋能：资源最大化与用户价值提升</div>
        </div>
      </div>
      <div
        style="width: 80%;margin: 0 auto;text-align: justify;line-height: 2rem;font-size: 1.2rem;margin-top: 1rem;text-indent: 2rem;">
        我们的平台为各行业合作伙伴提供高效的资源分发与精准的市场推广方案。通过深度的用户画像和数据分析，我们帮助合作伙伴优化营销策略，提高资源利用效率并提升用户价值，推动业务增长。
      </div>
    </div>
    <div style="margin-top: 2rem;color: #555555;margin-bottom: 3rem;">
      <div
        style="width: 80%;margin: 0 auto;letter-spacing: 0.1rem;font-weight: bolder;display: flex;justify-content: flex-start;align-items: center;"
        @click="gotoJoin">
        <div>
          <div style="font-size: 1.5rem;">创新驱动：价值共创，推动行业转型</div>
        </div>
      </div>
      <div
        style="width: 80%;margin: 0 auto;text-align: justify;line-height: 2rem;font-size: 1.2rem;margin-top: 1rem;text-indent: 2rem;">
        创新是我们发展的核心动力。我们致力于将权益服务从单一“工具”转变为价值共创的核心驱动力。通过持续的技术创新与商业模式优化，我们为行业带来数字化转型的解决方案，不断提升行业运营效率与市场影响力。
      </div>
    </div> -->

    <div style="width: 50%;margin: 0 auto;margin-top: 2rem;">
      <el-timeline>
        <el-timeline-item timestamp="权益生态整合：跨行业资源整合与全场景覆盖" placement="top">
          <el-card>
              我们通过打破行业壁垒，整合通信、消费、出行、娱乐等多个领域的优质资源，构建了一个跨行业、全场景的权益生态。我们的资源整合不仅为用户提供多样化的服务选择，还能帮助企业通过精细化管理提升资源利用效率，增强市场竞争力。
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="智能化分发：精准、个性化的权益分发能力" placement="top">
          <el-card>
              凭借领先的大数据分析与人工智能技术，我们为用户提供个性化的权益推荐。通过实时数据分析和智能算法，我们根据用户的行为、偏好与需求，精准推送适合的权益内容，提升用户体验和转化率。同时，我们确保各类资源的高效流转，最大化提升权益的分发效率。
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="用户体验：高效便捷的权益获取与管理" placement="top">
          <el-card>
            我们通过移动端应用及合作伙伴平台，提供便捷的权益获取通道，确保用户随时随地享受定制化的权益服务。通过持续的用户行为分析和反馈优化，我们不断提升推荐精度，为用户带来更高价值的体验。
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="合作伙伴赋能：资源最大化与用户价值提升" placement="top">
          <el-card>
            我们的平台为各行业合作伙伴提供高效的资源分发与精准的市场推广方案。通过深度的用户画像和数据分析，我们帮助合作伙伴优化营销策略，提高资源利用效率并提升用户价值，推动业务增长。
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="创新驱动：价值共创，推动行业转型" placement="top">
          <el-card>
            创新是我们发展的核心动力。我们致力于将权益服务从单一“工具”转变为价值共创的核心驱动力。通过持续的技术创新与商业模式优化，我们为行业带来数字化转型的解决方案，不断提升行业运营效率与市场影响力。
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>

    <div style="width: 80%;margin: 0 auto;margin-top: 3rem;">
      <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;text-align: center;">产品服务-产品在线服务</div>
      <div style="font-size: 1rem;color: #999999;text-align: center;">PRODUCT SERVICE</div>
    </div>

    <div
      style="width: 80%;margin: 0 auto;font-size: 1rem;text-align: center;color: #666;margin-top: 1rem;line-height: 1.5rem;line-height: 2rem;text-align: justify;">
      7*12小时400客服热线服务：<br>
      我们提供全天候（7天×12小时）客服热线，确保您在任何时间段都能得到及时的帮助。通过我们完善的客服平台系统，用户可以享受以下服务：在线咨询：用户可以通过平台随时发起咨询，获取专业的解答和帮助。<br>
      问题反馈：任何问题或疑虑都可以通过反馈功能提交，客服人员会迅速响应并处理。<br>
      实时追踪处理状态：用户能够实时查看问题的处理进度，确保问题得到及时解决。<br>
      订购流程溯源查询：提供订单追踪功能，用户可随时查询订购过程中的各个环节，确保透明度和安全性。<br>
    </div>
    <div style="width: 80%;margin: 0 auto;margin-top: 1rem;padding-bottom: 1rem;">
      <img style="width: 20%;display: block;margin: 0 auto;" :src="require('../../public/img/main/ground4.png')" alt="">
    </div>

    <bottom></bottom>

    <!-- <customerservice></customerservice> -->

  </div>
</template>

<script>
  import Tabbar from "../components/Tabbar.vue";
  import Bottom from "../components/Bottom.vue";
  import Carousel from "../components/Carousel.vue";

  export default {
    components: { Tabbar, Bottom, Carousel },
    data() {
      return {
        typeIndex: '0',
        typeList: [
          {
            urlCheck: require(`../../public/img/main/type1_check1.png`),
            urlUncheck: require(`../../public/img/main/type1_uncheck1.png`),
            text: '运营服务'
          },
          {
            urlCheck: require(`../../public/img/main/type2_check1.png`),
            urlUncheck: require(`../../public/img/main/type2_uncheck1.png`),
            text: '权益服务'
          },
          {
            urlCheck: require(`../../public/img/main/type3_check1.png`),
            urlUncheck: require(`../../public/img/main/type3_uncheck1.png`),
            text: '数据服务'
          },
          {
            urlCheck: require(`../../public/img/main/type4_check1.png`),
            urlUncheck: require(`../../public/img/main/type4_uncheck1.png`),
            text: '客户服务'
          }
        ],
        typebtmList: [
          {
            textTop: '专业支持：聚合顶尖运营人才，提供场景化营销方案策划，从创意构想到执行落地，全程精准对接客户需求。',
            textBtm: '全链路覆盖：涵盖技术开发、活动落地执行、数据监控、复盘优化等完整服务闭环，助力客户高效实现商业目标。',
            textBg: require(`../../public/img/main/type1_bg1.png`)
          },
          {
            textTop: '资源整合能力：汇聚300+优质品牌资源，将热点商品与营销活动有机结合，为客户提供卓越的权益运营解决方案。',
            textBtm: '全链协同：覆盖供应商寻源、管理、采购与接入等流程，搭建高效的权益管理体系，为客户赋能价值链升级。',
            textBg: require(`../../public/img/main/type2_bg1.png`)
          },
          {
            textTop: '数据资产管理：以数据治理和清洗为基础，构建高质量数据资产，支撑精准营销与运营优化。',
            textBtm: '洞察与预测：通过客户分层、营销分析及智能预测模型，赋能科学决策，帮助客户抢占市场先机。',
            textBg: require(`../../public/img/main/type3_bg1.png`)
          },
          {
            textTop: '智能化服务：提供全渠道、7*24小时智能客服解决方案，以极速响应构建卓越的客户体验。',
            textBtm: '全景用户视图：基于数据整合构建360°用户画像，实现全链路客户旅程管理，提升服务价值。',
            textBg: require(`../../public/img/main/type4_bg1.png`)
          }
        ]
      };
    },

    methods: {
      skill() {
        window.scrollTo(0, 0);
        this.$router.push("/ai");
      },
      honour() {
        window.scrollTo(0, 0);
        this.$router.push("/honour");
      },

      gotoPartner() {
        this.$router.push({ path: '/partner' })
      },
      gotoTrends() {
        this.$router.push({ path: '/trends' })
      },
      gotoJoin() {
        this.$router.push({ path: '/join' })
      }
    },
  };
</script>

<style scoped>
  * {
    margin: 0;
    padding: 0;
  }

  .el-timeline-item__timestamp {
    font-size: 1rem !important;
  }

  .bgtype>>>.tabFont:hover {
    color: rgb(233 192 131);
  }

  .bgtype>>>.tabListBut {
    color: #ffffff;
  }

  .buttype {
    background-color: transparent;
    border: 0;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  .flex {
    display: flex;
  }

  .flex1 {
    flex: 1;
  }

  .flexcol {
    flex-direction: column;
  }

  .w100 {
    width: 100%;
  }

  .w80 {
    width: 80%;
  }

  .w50 {
    width: 50%;
  }

  .minwidth {
    min-width: 1000px;
  }

  .minheight {
    min-height: 200px;
  }

  .textcent {
    text-align: center;
  }

  .positionrel {
    position: relative;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  .margincenter {
    margin: 0 auto;
  }

  .marginauto {
    margin: auto;
  }

  .margintop30 {
    margin-top: 30px;
  }

  .margintop70 {
    margin-top: 70px;
  }

  .marginbot20 {
    margin-bottom: 20px;
  }

  .marginbot40 {
    margin-bottom: 40px;
  }

  .fontsize14 {
    font-size: 14px;
  }

  .subtitle {
    font-size: 35px;
    font-weight: bold;
  }

  .lineheight30 {
    line-height: 30px;
  }

  .pic {
    width: 100%;
    border-radius: 10px;
  }

  .show1 {
    display: block;
  }

  .show2 {
    display: none;
  }

  .textIndent {
    text-indent: 32px;
    text-align: justify;
  }

  @media screen and (max-width: 750px) {
    .minwidth {
      min-width: 0px;
    }

    .flexnone {
      display: block;
    }

    .mw100 {
      width: 100vw;
    }

    .show1 {
      display: none;
    }

    .show2 {
      display: block;
    }
  }
</style>
<template>
  <div class="minwidth w100 mw100">
    <tabbar></tabbar>

    <div class="positionrel w100">
      <img :src="require('../../public/img/join/pic1.png')" class="positionabs" />
      <div style="color: #fff;font-size: 3rem;position: absolute;top: 8rem;left: 50%;transform: translate(-50%);">
        行业经典案例
      </div>
      <div
        style="text-align: center;color: #fff;cursor: pointer;background-color: #06D1ED;width: 60%;margin: 0 auto;height: 2rem;line-height: 2rem;border-radius: 2rem;font-size: 0.8rem;margin-top: 1rem;"
        @click="gotoJoin">获取解决方案</div>
    </div>

    <div v-if="type == '1'" style="width: 80%;margin: 0 auto;margin-top: 5rem;margin-bottom: 5rem;">
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div style="width: 40%;text-align: center;">
          <div style="text-align: left;color: #b0b0b0;font-weight: bold;cursor: pointer;" @click="routerPush">
            &lt; 返回
          </div>
          <img style="width: 80%;" :src="require('../../public/img/trends/case1.png')" alt="" />
        </div>
        <div style="width: 60%;">
          <div>
            <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
              <span style="font-weight: bolder;">项目名称：</span>
              <span style="font-size: 1rem;">江苏中石油</span>
            </div>
            <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
              <span style="font-weight: bolder;">合作客户：</span>
              <span style="font-size: 1rem;">中石油+中国移动</span>
            </div>
            <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
              <span style="font-weight: bolder;">项目类型：</span>
              <span style="font-size: 1rem;">存量增值</span>
            </div>
            <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
              <span style="font-weight: bolder;">方案定制：</span>
              <span style="font-size: 1rem;">20000元起</span>
            </div>
            <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
              <span style="font-weight: bolder;">关键词：</span>
              <span style="font-size: 1rem;">
                精细化市场分层、深度学习算法、行为驱动营销、全渠道用户洞察、人工智能驱动决策、客户生命周期分析、跨平台数据融合、用户画像深化、智能化流量调度、价值驱动转化率优化
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          style="display: flex;align-items: center;justify-content: center;width: 80%;margin: 0 auto;margin-top: 5rem;"
          class="mw100">
          <div v-for="(item,index) in typeList" style="text-align: center;cursor: pointer;" class="w100"
            @click="typeIndex = index">
            <img style="flex: 0.25;width: 20%;" :src="index == typeIndex ? item.urlCheck : item.urlUncheck">
            <div style="color: #999999;font-size: 1.3rem;margin-top: 1rem;"
              :style="index == typeIndex ? 'color: #00ceea':''">
              {{ item.text }}</div>
          </div>
        </div>
        <div v-if="typeIndex == '0'"
          style=" width: 90%;margin: 0 auto;margin-top: 1vw;background-color: #f8fafc;padding: 2rem;border-radius: 1rem;">
          <div style="letter-spacing: 0.1rem;line-height: 3rem;">
            项目依托的技术平台，以大数据分析与智能推荐算法为核心，实现用户需求的精准识别与权益的高效匹配。分布式架构设计确保系统的高并发处理能力和稳定运行，满足大规模用户的动态需求。实时监控与自动化运维技术增强了平台的稳定性与灵活性，同时支持数字营销生态闭环的构建，为业务的长期增长提供强有力的技术支持。
          </div>
          <img style="width: 90%;display: block;margin: 0 auto;margin-top: 1rem;"
            :src="require(`../../public/img/trends/type1_bg1.png`)" alt="">
        </div>
        <div v-if="typeIndex == '1'"
          style=" width: 90%;margin: 0 auto;margin-top: 1vw;background-color: #f8fafc;padding: 2rem;">
          <div style="letter-spacing: 0.1rem;line-height: 3rem;">
            江苏中石油项目以用户需求为导向，聚焦有车一族的高频场景，整合中石油加油券与话费券权益，精准激活20W+存量用户。项目采用数据驱动的精细化运营策略，通过用户画像分析与分层运营，提升触达效率与用户活跃度。灵活的活动设计与实时调整机制保障了权益的高效分发和用户的持续参与，助力企业在存量市场中实现价值深挖与粘性提升。
          </div>
          <img style="width: 90%;display: block;margin: 0 auto;margin-top: 1rem;"
            :src="require(`../../public/img/trends/type2_bg1.png`)" alt="">
        </div>
        <div v-if="typeIndex == '2'"
          style=" width: 90%;margin: 0 auto;margin-top: 1vw;background-color: #f8fafc;padding: 2rem;">
          <div style="letter-spacing: 0.1rem;line-height: 3rem;">
            客服体系以“专业、高效、贴心”为核心目标，构建智能化与人工服务相结合的多层次服务能力。通过自然语言处理技术的AI客服机器人，快速解决用户在权益查询、使用中的常见问题，提供7×24小时的即时响应服务。针对复杂场景，人工客服团队凭借丰富的行业经验与系统化服务流程，确保问题的快速解决与用户满意度提升。同时，服务质量监控工具与用户反馈机制支持服务流程的持续优化，不仅提升用户体验，还树立了企业“服务至上”的品牌形象。
          </div>
          <img style="width: 90%;display: block;margin: 0 auto;margin-top: 1rem;"
            :src="require(`../../public/img/trends/type3_bg1.png`)" alt="">
        </div>
      </div>
    </div>

    <div v-if="type == '2'" style="width: 80%;margin: 0 auto;margin-top: 5rem;margin-bottom: 5rem;">
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div style="width: 60%;">
          <div style="text-align: left;color: #b0b0b0;font-weight: bold;cursor: pointer;" @click="routerPush">
            &lt; 返回
          </div>
          <div style="font-size: 1.2rem;margin-top: 2rem;line-height: 2rem;">
            <span style="font-weight: bolder;">项目名称：</span>
            <span style="font-size: 1rem;">
              你是我的汉堡包
            </span>
          </div>
          <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
            <span style="font-weight: bolder;">合作客户：</span>
            <span style="font-size: 1rem;">
              麦当劳+中国电信
            </span>
          </div>
          <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
            <span style="font-weight: bolder;">项目类型：</span>
            <span style="font-size: 1rem;">
              存量增值
            </span>
          </div>
          <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
            <span style="font-weight: bolder;">方案定制：</span>
            <span style="font-size: 1rem;">20000元起</span>
          </div>
          <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
            <span style="font-weight: bolder;">关键词：</span>
            <span style="font-size: 1rem;">
              深度自然语言理解（NLU）、情感分析模型、智能客服自动化、跨语言自动翻译、高级语音识别、AI增强客户交互、自动化工单流转、智能客服智能调度、跨平台整合服务、客户行为预测
            </span>
          </div>
        </div>
        <div style="width: 40%;text-align: center;">
          <img style="width: 80%;" :src="require('../../public/img/trends/case2.png')" alt="" />
        </div>
      </div>
      <div>
        <div
          style="display: flex;align-items: center;justify-content: center;width: 80%;margin: 0 auto;margin-top: 5rem;"
          class="mw100">
          <div v-for="(item,index) in typeList" style="text-align: center;cursor: pointer;" class="w100"
            @click="typeIndex = index">
            <img style="flex: 0.25;width: 20%;" :src="index == typeIndex ? item.urlCheck : item.urlUncheck">
            <div style="color: #999999;font-size: 1.3rem;margin-top: 1rem;"
              :style="index == typeIndex ? 'color: #00ceea':''">
              {{ item.text }}</div>
          </div>
        </div>
        <div v-if="typeIndex == '0'"
          style=" width: 90%;margin: 0 auto;margin-top: 1vw;background-color: #f8fafc;padding: 2rem;border-radius: 1rem;">
          <div style="letter-spacing: 0.1rem;line-height: 3rem;">
            项目以智能化技术架构为基础，构建高度集成的大数据分析平台和实时监控系统。通过用户行为建模与预测算法，实现精确的需求匹配与动态资源调度。分布式架构设计保障系统的高性能与可扩展性，而自动化营销工具支持大规模用户激活与权益分发的稳定运行。项目采用闭环数据管理策略，将实时反馈融入技术优化流程，增强系统的适配性与响应能力，为业务增长提供强有力的技术支持。
          </div>
          <img style="width: 90%;display: block;margin: 0 auto;margin-top: 1rem;"
            :src="require(`../../public/img/trends/type1_bg2.png`)" alt="">
        </div>
        <div v-if="typeIndex == '1'"
          style=" width: 90%;margin: 0 auto;margin-top: 1vw;background-color: #f8fafc;padding: 2rem;">
          <div style="letter-spacing: 0.1rem;line-height: 3rem;">
            本项目围绕存量用户精细化运营，通过高效的用户分层与动态行为分析，实现全场景触达与全生命周期管理。采用数据驱动的运营决策，结合智能化活动策划与自动化执行平台，全面提升运营效率与市场渗透率。项目整合多渠道资源，优化触达链路，确保用户高频互动与深度留存。同时，基于实时监控和反馈机制，持续迭代优化运营策略，强化市场竞争力和用户粘性。
          </div>
          <img style="width: 90%;display: block;margin: 0 auto;margin-top: 1rem;"
            :src="require(`../../public/img/trends/type2_bg2.png`)" alt="">
        </div>
        <div v-if="typeIndex == '2'"
          style=" width: 90%;margin: 0 auto;margin-top: 1vw;background-color: #f8fafc;padding: 2rem;">
          <div style="letter-spacing: 0.1rem;line-height: 3rem;">
            客服体系以技术赋能为核心，打造集成化、智能化、多层次的服务支持能力。AI客服机器人基于深度学习算法与自然语言处理技术，快速响应用户问题并实现常规场景下的自动化处理。对于复杂场景，系统支持多渠道无缝转接至人工客服，确保服务的专业性与高效性。通过智能质检与用户反馈分析工具，客服团队不断优化服务流程与问题解决效率，全面提升用户满意度与品牌信赖度，为企业运营和技术架构提供重要支撑。
          </div>
          <img style="width: 90%;display: block;margin: 0 auto;margin-top: 1rem;"
            :src="require(`../../public/img/trends/type3_bg2.png`)" alt="">
        </div>
      </div>
    </div>

    <div v-if="type == '3'" style="width: 80%;margin: 0 auto;margin-top: 5rem;margin-bottom: 5rem;">
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div style="width: 40%;text-align: center;">
          <div style="text-align: left;color: #b0b0b0;font-weight: bold;cursor: pointer;" @click="routerPush">
            &lt; 返回
          </div>
          <img style="width: 80%;" :src="require('../../public/img/trends/case3.png')" alt="">
        </div>
        <div style="width: 60%;">
          <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
            <span style="font-weight: bolder;">项目名称：</span>
            <span style="font-size: 1rem;">
              搜狐会员
            </span>
          </div>
          <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
            <span style="font-weight: bolder;">合作客户：</span>
            <span style="font-size: 1rem;">
              中国联通
            </span>
          </div>
          <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
            <span style="font-weight: bolder;">项目类型：</span>
            <span style="font-size: 1rem;">
              存量增值
            </span>
          </div>
          <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
            <span style="font-weight: bolder;">方案定制：</span>
            <span style="font-size: 1rem;">20000元起</span>
          </div>
          <div style="font-size: 1.2rem;margin-top: 0.8rem;line-height: 2rem;">
            <span style="font-weight: bolder;">关键词：</span>
            <span style="font-size: 1rem;">
              分布式大数据架构、高可用性架构设计、容器化部署、微服务架构、弹性负载均衡、容错性设计、数据管道自动化、高效边缘计算、实时数据流处理、异构数据整合与处理
            </span>
          </div>
        </div>
      </div>
      <div>
        <div
          style="display: flex;align-items: center;justify-content: center;width: 80%;margin: 0 auto;margin-top: 5rem;"
          class="mw100">
          <div v-for="(item,index) in typeList" style="text-align: center;cursor: pointer;" class="w100"
            @click="typeIndex = index">
            <img style="flex: 0.25;width: 20%;" :src="index == typeIndex ? item.urlCheck : item.urlUncheck">
            <div style="color: #999999;font-size: 1.3rem;margin-top: 1rem;"
              :style="index == typeIndex ? 'color: #00ceea':''">
              {{ item.text }}</div>
          </div>
        </div>
        <div v-if="typeIndex == '0'"
          style=" width: 90%;margin: 0 auto;margin-top: 1vw;background-color: #f8fafc;padding: 2rem;border-radius: 1rem;">
          <div style="letter-spacing: 0.1rem;line-height: 3rem;">
            方案依托智能推荐系统、数据挖掘技术和动态分析平台，为用户提供精准匹配的个性化权益体验。基于高效的权益分发算法，用户可以即时领取并激活所选权益。自动化营销平台通过实时反馈机制，优化用户参与路径，提升产品粘性。此外，方案利用高频场景触发技术与实时监控系统，确保服务的稳定性和高效性，从而最大化市场价值的释放，支持企业实现持续增长。
          </div>
          <img style="width: 90%;display: block;margin: 0 auto;margin-top: 1rem;"
            :src="require(`../../public/img/trends/type1_bg3.png`)" alt="">
        </div>
        <div v-if="typeIndex == '1'"
          style=" width: 90%;margin: 0 auto;margin-top: 1vw;background-color: #f8fafc;padding: 2rem;">
          <div style="letter-spacing: 0.1rem;line-height: 3rem;">
            搜狐会员增值服务方案以存量用户需求为核心，通过深度洞察用户行为和偏好，精准覆盖高频消费场景。通过灵活的权益设计，用户可以自由选择2款热门权益（如影视会员等），并享受云游戏等娱乐服务与生活周边礼包。这种个性化权益组合不仅提升了用户满意度，还优化了产品触达效率。同时，方案通过动态调整和多渠道推广，推动用户活跃度提升，为企业构建更稳固的用户生态。
          </div>
          <img style="width: 90%;display: block;margin: 0 auto;margin-top: 1rem;"
            :src="require(`../../public/img/trends/type2_bg3.png`)" alt="">
        </div>
        <div v-if="typeIndex == '2'"
          style=" width: 90%;margin: 0 auto;margin-top: 1vw;background-color: #f8fafc;padding: 2rem;">
          <div style="letter-spacing: 0.1rem;line-height: 3rem;">
            在用户权益体验中，方案配备了多渠道、智能化的客服支持体系。通过智能客服机器人和实时人工客服的结合，为用户提供7×24小时的高效服务，快速解决问题并提升服务体验。客服系统还集成了用户反馈收集与分析模块，实时捕捉用户需求并推动权益方案的优化迭代，为用户创造更加便捷和贴心的服务体验，同时助力企业持续提升服务质量和用户满意度。
          </div>
          <img style="width: 90%;display: block;margin: 0 auto;margin-top: 1rem;"
            :src="require(`../../public/img/trends/type3_bg3.png`)" alt="">
        </div>
      </div>
    </div>

    <bottom></bottom>

    <customerservice></customerservice>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        type: '1',

        typeIndex: '0',
        typeList: [
          {
            urlCheck: require(`../../public/img/trends/type1_check.png`),
            urlUncheck: require(`../../public/img/trends/type1_uncheck.png`),
            text: '智能营销'
          },
          {
            urlCheck: require(`../../public/img/trends/type2_check.png`),
            urlUncheck: require(`../../public/img/trends/type2_uncheck.png`),
            text: '数据研发'
          },
          {
            urlCheck: require(`../../public/img/trends/type3_check.png`),
            urlUncheck: require(`../../public/img/trends/type3_uncheck.png`),
            text: '智慧运营'
          },
        ]
      };
    },

    mounted() {
      this.type = this.$route.query.id
    },

    methods: {
      routerPush() {
        this.$router.push({ path: '/trends' })
      },
      gotoJoin() {
        this.$router.push({ path: '/join' })
      }
    },
  };
</script>

<style scoped>
  * {
    margin: 0;
    padding: 0;
  }

  .w100 {
    width: 100%;
  }

  .w90 {
    width: 90%;
  }

  .w60 {
    width: 60%;
  }

  .w30 {
    width: 30%;
  }

  .w200 {
    width: 200px;
  }

  .minwidth {
    min-width: 1000px;
  }

  .flex {
    display: flex;
  }

  .textcent {
    text-align: center;
  }

  .fontsize50 {
    font-size: 50px;
  }

  .fontsize20 {
    font-size: 20px;
  }

  .fontsize14 {
    font-size: 14px;
  }

  .fontsize12 {
    font-size: 12px;
  }

  .fontcolorb0 {
    color: #b0b0b0;
  }

  .margincent {
    margin: 0 auto;
  }

  .margincentY {
    margin: auto 0;
  }

  .margintop20 {
    margin-top: 20px;
  }

  .margintop50 {
    margin-top: 50px;
  }

  .margintop70 {
    margin-top: 70px;
  }

  .marginleft20 {
    margin-left: 20px;
  }

  .marginbot40 {
    margin-bottom: 40px;
  }

  .paddingtop20 {
    padding-top: 20px;
  }

  .title {
    color: #000
  }

  .title:hover {
    color: #bc9158;
  }

  .positionrel {
    position: relative;

    height: 320px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 1920px;
    -webkit-filter: contrast(50%);
    filter: contrast(50%);
  }

  .textAlign_justify {
    text-align: justify;
  }

  .whitespace {
    white-space: pre-wrap;
    line-height: 30px;
  }

  @media screen and (max-width: 750px) {
    .minwidth {
      min-width: 0px;
    }

    .mw100 {
      width: 100vw;
    }

    .flexnone {
      display: block;
    }

    .w80 {
      width: 80%;
    }

    .w300 {
      width: 300px;
    }

    .fontsize30 {
      font-size: 30px;
    }

    .marginleft20 {
      margin-left: 0;
    }

    .title {
      color: #bc9158;
    }

    .positionrel {
      position: relative;

      height: 179px;
      overflow: hidden;
    }

    .positionabs {
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      width: 780px;
    }
  }
</style>
<template>
  <div class="minwidth w100">
    <tabbar></tabbar>

    <div class="positionrel w100">
      <img :src="require('../../public/img/join/pic2.png')" class="positionabs" />
      <div style="color: #fff;font-size: 3rem;position: absolute;top: 8rem;left: 50%;transform: translate(-50%);">
        加入我们
      </div>
    </div>

     <!--contact-->
     <div class="contactBox" style="margin-bottom: 20rem;">
      <div class="warpper">
        <div class="titBox">
          <h3>联系我们</h3>
          <!-- <p>一个新的需求，从这里开始。欢迎填写表格或发送合作邮件至：<a class="C_Data_MailLink" href="javascript:void(0);"><span
                class="C_Data_Mail"></span></a></p> -->
        </div>
        <div class="formBox clearfix">
          <div class="formBox_l">
            <div class="form_item">
              <label>您的姓名：</label>
              <input class="inputText outlineBox" type="text" placeholder="您的姓名：" id="J_Submit_Name">
            </div>
            <div class="form_item">
              <label>您的邮箱：</label>
              <input class="inputText outlineBox" type="text" placeholder="您的邮箱：" id="J_Submit_Mail">
            </div>
            <div class="form_item">
              <label>您的电话：</label>
              <input class="inputText outlineBox" type="text" placeholder="您的电话：" id="J_Submit_Phone">
            </div>
          </div>
          <div class="formBox_r">
            <label>合作需求：</label>
            <textarea class="textAreaBox outlineBox" placeholder="合作需求：项目需求等" id="J_Submit_Message"></textarea>
          </div>
        </div>
        <div class="xqBox clearfix">
          <div class="xqBox_r">
            <a href="javascript:void(0);" id="J_Submit_Btn">提交</a>
          </div>
          <div class="xqBox_l">
            <p>地址：<span class="C_Data_Address">上海市崇明区庙镇宏海公路2050号（上海庙镇经济开发区）</span></p>
            <p>联系电话：<a class="C_Data_PhoneLink"><span class="C_Data_Phone">4000006502</span></a></p>
            <!-- <p class="hotRed">咨询热线：<a class="C_Data_PhoneLink"><span class="C_Data_Phone"></span></a></p> -->
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="w100 margintop50">
      <div class="margincent textcent fontsize50 margintop50 fontsize30 mw80" style="font-size: 2rem;">
        招聘信息
      </div>
    </div>
    <div v-for="item in msglist" :key="item.job" class="w60 margincent margintop50 marginbot40 minwidth mw80">
      <div>
        <div>
          <el-button type="text" class="job fontsize20 marginbot20" @click="jobmsg(item.num)">{{ item.job }}</el-button>
        </div>
        <div class="margintop20 fontsize14 fontcolorb0 textalign_justify">
          <div>职位描述：</div>
          <div v-for="(item,index) in item.msg" :key="index">
            {{ item }}
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="w60 margincent margintop70 marginbot40 minwidth fontsize14 mw80">
      人才招聘：请将简历发送至heyuxuan@shumingdata.com
    </div> -->

    <bottom></bottom>

    <customerservice></customerservice>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        msglist: [
          {
            num: 0,
            job: "高级数据分析师",
            msg: ["1. 为产品运营团队提供数据分析和用户与产品的推荐数据支持；", "2. 负责整理各业务数据，对多种数据源的进行深度诊断性组合分析、挖掘深度分析和建模，对相关数据进行深度挖掘分析；", "3. 参与实际的推荐营销业务，通过分析、建模用户的各项特征数据，构建规则、模型来挖掘营销产品最相关的用户群；", "4. 深入理解业务，发现业务特征潜在机会，并给出有效的行动建议；", "5. 对公司业务的运营进行评估和建议，从数据的角度推动公司运营决策、产品方向和运营。"]
          },
          // {
          //   num: 1,
          //   job: "商务经理",
          //   msg: "职位描述：1、负责公司合作机构客户沟通，获取客户在大数据方面的需求，进行公司平台产品的业务拓展；2、通过对业务数据的分析,结合合同运营的实际情况,洞察业务问题并反馈优化建议；3、监控项目的整体执行情况,控制合同的商务风险,对相关业务数据进行准确判断；",
          // },
          {
            num: 1,
            job: "推荐算法工程师",
            msg: ["1.基于用户和行为数据，建立推荐算法与策略；", "2.梳理业务逻辑，流程，数据；", "3.对用户行为数据进行分析，挖掘，实现个性化推荐、精准推荐算法；"],
          },
          // {
          //   num: 3,
          //   job: "高级前端工程师",
          //   msg: "职位描述：1.根据产品需求和原型，完成页面设计及前段功能开发；2.前后端接口对接，完成数据交互；3.参与需求评审、项目排期、架构设计和编码；4.遵循代码规范，保证交付代码质量；5.持续不断优化代码；6.兼容性问题处理；7.前端性能优化；",
          // },
        ],
      };
    },

    methods: {
      jobmsg(num) {
        window.scrollTo(0, 0);
        this.$router.push({
          path: "/jobs",
          query: {
            num: num,
          },
        });
      },
    },
  };
</script>

<style scoped>
  /*联系我们板块*/
  .contactBox {
    padding-bottom: 60px;
  }
  .contactBox .titBox {
    padding-top: 0x;
    margin-bottom: 40px;
    text-align: center;
  }
  .contactBox .titBox h3 {
    line-height: 96px;
    font-size: 30px;
    color: #4a4a4a;
  }
  .contactBox .titBox p {
    line-height: 30px;
    font-size: 16px;
    color: #999;
  }
  .contactBox .titBox a {
    color: #8e6dcd;
  }
  .contactBox .formBox_l {
    float: left;
    width: 50%;
    padding-right: 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
  }
  .contactBox .form_item {
    position: relative;
    margin-bottom: 20px;
  }
  .contactBox .form_item:after {
    content: "*";
    position: absolute;
    top: 15px;
    left: 93%;
    color: #000;
    opacity: 0.7;
    font-size: 20px;
  }
  .contactBox label {
    display: none;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: #000;
    opacity: 0.7;
  }
  .contactBox .inputText {
    padding: 6px 12px;
    width: 100%;
    height: 52px;
    font-size: 14px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
  }
  .contactBox .formBox_r {
    float: right;
    width: 50%;
    padding-left: 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
  }
  .contactBox .textAreaBox {
    width: 100%;
    height: 195px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    resize: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
  }
  .contactBox .xqBox_l {
    float: left;
    width: 50%;
    line-height: 35px;
    color: #4a4a4a;
  }
  .contactBox .xqBox_l a {
    color: #4a4a4a;
  }
  .contactBox .xqBox_l a:hover {
    color: #4a4a4a;
    opacity: 0.8;
  }
  .contactBox .xqBox_l p.hotRed {
    font-weight: bold;
    color: #8e6dcd;
  }
  .contactBox .xqBox_l p.hotRed a {
    color: #8e6dcd;
  }
  .contactBox .xqBox_l p.hotRed a:hover {
    color: #8e6dcd;
  }
  .contactBox .xqBox_r {
    float: right;
    width: 50%;
    padding: 20px 0px 15px;
    text-align: right;
  }
  .contactBox .xqBox_r a {
    display: inline-block;
    padding: 6px 12px;
    width: 104px;
    height: 35px;
    line-height: 23px;
    text-align: center;
    background: #333;
    font-size: 14px;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
  }
  .contactBox .xqBox_r a:hover {
    opacity: 0.8;
  }



  .w100 {
    width: 100%;
  }

  .w60 {
    width: 60%;
  }

  .minwidth {
    min-width: 1000px;
  }

  .flex {
    display: flex;
  }

  .textcent {
    text-align: center;
  }

  .textalign_justify {
    text-align: justify;
    line-height: 25px;
  }

  .fontsize50 {
    font-size: 50px;
  }

  .fontsize20 {
    font-size: 20px;
  }

  .fontsize14 {
    font-size: 14px;
  }

  .fontsize12 {
    font-size: 12px;
  }

  .fontcolorb0 {
    color: #b0b0b0;
  }

  .margincent {
    margin: 0 auto;
  }

  .margincentY {
    margin: auto 0;
  }

  .margintop20 {
    margin-top: 20px;
  }

  .margintop50 {
    margin-top: 50px;
  }

  .margintop70 {
    margin-top: 70px;
  }

  .marginleft20 {
    margin-left: 20px;
  }

  .marginbot40 {
    margin-bottom: 40px;
  }

  .job {
    color: #000;
  }

  .job:hover {
    color: #bc9158;
  }

  .positionrel {
    position: relative;
    height: 340px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 1920px;
  }

  @media screen and (max-width: 750px) {
    .minwidth {
      min-width: 0px;
    }

    .mw100 {
      width: 100vw;
    }

    .flexnone {
      display: block;
    }

    .mw80 {
      width: 80%;
    }

    .fontsize30 {
      font-size: 30px;
    }

    .job {
      color: #bc9158;
    }

    .positionrel {
      position: relative;

      height: 179px;
      overflow: hidden;
    }

    .positionabs {
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      width: 780px;
    }
  }
</style>
<template>
  <div class="minwidth w100 mw100" style="position: relative">
    <tabbar></tabbar>

    <!-- <carousel2></carousel2> -->

    <div style="width: 80%;margin: 0 auto;display: flex;justify-content: space-between;align-items: center;margin-top: 2rem;"
      class="mw100">
      <div style="width: 35%;">
        <img style="width: 100%;" :src="require('../../public/img/partner/about_2.png')" alt="">
      </div>
      <div style="width: 60%;">
        <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;">关于我们</div>
        <div style="font-size: 0.9rem;color: #999999;">ABOUT US</div>
        <div
          style="color: #666666;font-size: 1.2rem;line-height: 2rem;text-align: justify;margin-top: 2rem;">
          ●上海哲喆智慧信息科技有限公司立于2022年3月，是一家专注于数字增值产品服务的创新型科技企业。我们深耕互联网及通信领域，服务对象涵盖互联网企业、通信行业及三大运营商（联通、电信、移动），致力于为客户提供一体化、专业化的解决方案。
        </div>
        <div style="display: flex;justify-content: space-around;align-items: center;width: 100%;margin-top: 1rem;">
          <div style="width: 20%;background-color: #29b3ca;text-align: center;height: 4rem;line-height: 4rem;color: #fff;font-size: 1.2rem;font-weight: bold;border-radius: 0.3rem;">
            产品策划
          </div>
          <div style="width: 20%;background-color: #4571db;text-align: center;height: 4rem;line-height: 4rem;color: #fff;font-size: 1.2rem;font-weight: bold;border-radius: 0.3rem;">
            广告投放
          </div>
          <div style="width: 20%;background-color: #29b3ca;text-align: center;height: 4rem;line-height: 4rem;color: #fff;font-size: 1.2rem;font-weight: bold;border-radius: 0.3rem;">
            大数据中台
          </div>
          <div style="width: 20%;background-color: #4571db;text-align: center;height: 4rem;line-height: 4rem;color: #fff;font-size: 1.2rem;font-weight: bold;border-radius: 0.3rem;">
            内容提供方
          </div>
        </div>
      </div>
    </div>

    <div style="width: 80%;margin: 0 auto;display: flex;justify-content: space-between;align-items: center;margin-top: 2rem;"
      class="mw100">
      <div style="width: 60%;">
        <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;">我们的愿景</div>
        <div style="font-size: 0.9rem;color: #999999;">OUR VISION</div>
        <div
          style="color: #666666;font-size: 1.2rem;text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
          是成为广告投放与数字增值产品领域的引领者，与客户建立长期互信的合作伙伴关系，共同推动互联网广告行业的高质量发展。
        </div>
        <div
          style="color: #666666;font-size: 1.2rem;text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
          核心业务涵盖产品策划、广告投放、大数据中台构建及内容服务，帮助客户实现业务增值与GMV增长。依托精准的数据分析、领先的技术能力及深厚的行业积累，我们为客户提供贯穿全链路的解决方案，以推动数字生态体系的构建与优化。
        </div>
      </div>
      <div style="width: 35%;">
        <img style="width: 100%;" :src="require('../../public/img/partner/about_3.png')" alt="">
      </div>
    </div>

    <div>
      <div style="font-size: 2rem;font-weight: bolder;text-align: center;color: #111111;margin-top: 3rem;">我们的团队</div>
      <div style="font-size: 1rem;text-align: center;color: #999999;">TEAM INTRODUCTION</div>
      <div style="width: 80%;margin: 0 auto;margin-top: 1rem;color: #666666;font-size: 1.2rem;line-height: 2.1rem;text-align: justify;text-indent: 2em;">
        上海哲喆智慧信息科技有限公司总部位于上海，专注于互联网产品推广及运营商行业服务，业务覆盖全国，与头部互联网媒体及知名应用建立了深度合作关系。公司团队聚集了国内顶尖的互联网产品、研发及运营人才，具备丰富的通信产品推广运营经验。通过精准洞察行业痛点，持续探索数字化转型路径，为运营商实现高效商业增长提供强力支持。
      </div>
      <div
        style="display: flex;width: 70%;margin: 0 auto;justify-content: space-between;align-items: center;background-color: #f8fafc;margin-top: 3vw;">
        <img style="width: 35%;" :src="require('../../public/img/partner/work1_1.png')" alt="">
        <div style="width: 60%;padding: 2rem;letter-spacing: 0.1rem;">
          <div style="margin-top: 1rem;">{{ '技术与创新' }}</div>
          <div style="margin-top: 2rem;line-height: 2rem;text-align: justify;">{{ '专注于人工智能、大数据处理等高新技术领域，打造了一支数十人的技术攻坚团队，确保在移动互联网营销领域的技术领先地位。' }}
          </div>
        </div>
      </div>
      <div
        style="display: flex;width: 70%;margin: 0 auto;justify-content: space-between;align-items: center;background-color: #f8fafc;margin-top: 3vw;">
        <div style="width: 60%;padding: 2rem;letter-spacing: 0.1rem;">
          <div style="margin-top: 1rem;">{{ '全方位服务能力' }}
          </div>
          <div style="margin-top: 2rem;line-height: 2rem;text-align: justify;">{{ '具备优秀的产品推广、技术支持和客户服务能力，帮助合作伙伴实现营销转化最大化。凭借专业团队与创新能力，哲喆科技将继续为行业客户提供卓越服务，助力运营商实现商业价值飞跃。' }}</div>
        </div>
        <img style="width: 35%;" :src="require('../../public/img/partner/work2_1.png')" alt="">
      </div>
    </div>

    <!-- <div>
      <div style="font-size: 2rem;font-weight: bolder;text-align: center;color: #111111;margin-top: 3rem;">公司历程</div>
      <div style="font-size: 1rem;text-align: center;color: #999999;">COMPANY HISTORY</div>
      <div style="width: 50%;margin: 0 auto;margin-top: 2rem;">
        <el-timeline>
          <el-timeline-item timestamp="2021年09月" placement="top">
            <el-card>
              <h3>上海格甜信息科技有限公司成立</h3>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2022年2月" placement="top">
            <el-card>
              <h3>内容服务中台</h3>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2022年5月" placement="top">
            <el-card>
              <h3>中国联通内容服务提供商</h3>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2023年2月" placement="top">
            <el-card>
              <h3>在线服务C端用户突破1100W大关</h3>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2023年3月" placement="top">
            <el-card>
              <h3>上线AI算力推荐推广服务</h3>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2024年3月" placement="top">
            <el-card>
              <h3>以头部投放公司达成推广合作</h3>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="至今" placement="top">
            <el-card>
              <h3>与字节跳动、支付宝、微信、整合市场定制化推广</h3>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div> -->

    <div style="font-size: 2rem;font-weight: bolder;text-align: center;color: #111111;margin-top: 3rem;">合作伙伴</div>
    <div style="font-size: 1rem;text-align: center;color: #999999;">COOPERATIVE PARTNER</div>

    <div style="background-color: #f8fafc;margin-top: 1rem;padding-bottom: 2rem;">
      <div style="font-size: 1.5rem;text-align: center;color: #313131;padding-top: 2rem;">
        合作伙伴150+知名企业的优质合作选择：
      </div>
      <div style="font-size: 1rem;text-align: center;color: #666;margin-top: 1rem;">
        哲喆信息持续拓展业务版图，为内容推广数字化转型和业务增长提供全方位支持，推动行业高效发展。
      </div>
      <div style="display: flex;flex-wrap: wrap;width: 80%;margin: 0 auto;align-items: center;margin-top: 1rem;">
        <div style="width: 100%;text-align: center;display: flex;align-items: center;justify-content: center;">
          <img style="width: 100%;" :src="require(`../../public/img/partner/partner0_1.png`)" alt="">
        </div>
      </div>
    </div>

    <bottom></bottom>

    <!-- <customerservice></customerservice> -->

  </div>
</template>

<script>
  export default {
    data() {
      return {
        typeIndex: 0
      };
    },
  };
</script>

<style scoped>
  .outbody {
    background-color: #1D1D1D;
    min-height: 1000px;
    margin: 0;
    font-family: 'Droid Sans', sans-serif;

    &:before {
      content: '';
      position: fixed;
      top: 0px;
      left: 50%;
      bottom: 0px;
      transform: translateX(-50%);
      width: 4px;
      background-color: #fff;
    }

    .entries {
      width: calc(100% - 80px);
      max-width: 800px;
      margin: auto;
      position: relative;
      left: -5px;

      .entry {
        width: calc(50% - 80px);
        float: left;
        padding: 20px;
        clear: both;
        text-align: right;

        &:not(:first-child) {
          margin-top: -60px;
        }

        .title {
          font-size: 32px;
          margin-bottom: 12px;
          position: relative;
          color: #fff;

          &:before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border: 4px solid #ffffff;
            background-color: #1D1D1D;
            border-radius: 100%;
            top: 50%;
            transform: translateY(-50%);
            right: -73px;
            z-index: 1000;
          }

          &.big:before {
            width: 24px;
            height: 24px;
            transform: translate(8px, -50%);
          }
        }

        .body {
          color: #aaa;

          p {
            line-height: 1.4em;
          }
        }

        &:nth-child(2n) {
          text-align: left;
          float: right;

          .title {
            &:before {
              left: -63px;
            }

            &.big:before {
              transform: translate(-8px, -50%);
            }
          }
        }
      }
    }
  }


  * {
    margin: 0;
    padding: 0;
  }

  .w100 {
    width: 100%;
  }

  .w80 {
    width: 80%;
  }

  .minwidth {
    min-width: 1000px;
  }

  .margintop50 {
    margin-top: 50px;
  }

  .margintop70 {
    margin-top: 70px;
  }

  .textcent {
    text-align: center;
  }

  .fontsize50 {
    font-size: 50px;
  }

  .margincent {
    margin: 0 auto;
  }

  .positionrel {
    position: relative;

    height: 440px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 1920px;
  }

  @media screen and (max-width: 750px) {
    .minwidth {
      min-width: 0px;
    }

    .mw100 {
      width: 100vw;
    }

    .flexnone {
      display: block;
    }

    .fontsize30 {
      font-size: 30px;
    }

    .positionrel {
      position: relative;

      height: 179px;
      overflow: hidden;
    }

    .positionabs {
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      width: 780px;
    }
  }
</style>
<template>
  <div class="minwidth w100 mw100">
    <tabbar></tabbar>

    <div>
      <div class="positionrel w100">
        <img :src="require('../../public/img/join/pic1.png')" class="positionabs" />
        <div style="color: #fff;font-size: 3rem;position: absolute;top: 8rem;left: 50%;transform: translate(-50%);">
          行业经典案例
          <div
            style="text-align: center;color: #fff;cursor: pointer;background-color: #06D1ED;width: 60%;margin: 0 auto;height: 2rem;line-height: 2rem;border-radius: 2rem;font-size: 0.8rem;margin-top: 1rem;"
            @click="gotoJoin">获取解决方案</div>
        </div>
      </div>

      <div style="width: 80%;margin: 0 auto;">
        <div style="font-size: 1.8rem;text-align: center;color: #313131;padding-top: 3rem;font-weight: bold;">
          通信行业解决方案
        </div>
        <div
          style="font-size: 1rem;line-height: 2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 2rem;">
          <span style="font-weight: bolder;font-size: 1.2rem;">场景痛点：<br></span>
          用户对基础通信服务的需求趋于饱和，需要更多增值服务。<br>
          竞争激烈，客户流失率高，用户忠诚度难以提升。<br>
          根据客户需求搭建<br>
        </div>
        <div
          style="font-size: 1rem;line-height: 2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 1rem;">
          <span style="font-weight: bolder;font-size: 1.2rem;">解决方案：<br></span>
          增值权益整合：提供流量包、语音套餐、宽带升级等定制化权益，结合合作品牌的消费优惠，为用户创造更多附加价值。<br>
          智能化推荐：基于用户通信行为与消费能力，推荐个性化权益方案，提高用户感知价值。<br>
          客户忠诚计划：设计积分兑换、专属会员权益等策略，提升用户留存率与黏性。<br>
          实时监控与反馈：通过数据分析平台监测权益使用情况，帮助运营商动态调整策略，优化用户体验。<br>
        </div>
        <div
          style="display: flex;justify-content: space-between;align-items: center;text-align: center;margin-top: 2rem;">
          <img style="width: 100%;" :src="require('../../public/img/trends/placeList1.png')" />
        </div>
        <div
          style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 2rem;cursor: pointer;border-bottom: 0.2rem solid #e9e9e9;height: 5rem;"
          @click="gotoJoin">
          获取解决方案 >
        </div>
      </div>

      <div style="width: 80%;margin: 0 auto;">
        <div style="font-size: 1.8rem;text-align: center;color: #313131;padding-top: 3rem;font-weight: bold;">
          消费行业解决方案
        </div>
        <div
          style="font-size: 1rem;line-height: 2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 2rem;">
          <span style="font-weight: bolder;font-size: 1.2rem;">场景痛点：<br></span>
          消费者需求多样，品牌难以覆盖全场景消费链条。<br>
          市场竞争加剧，品牌需要精准触达目标用户。<br>
        </div>
        <div
          style="font-size: 1rem;line-height: 2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 1rem;">
          <span style="font-weight: bolder;font-size: 1.2rem;">解决方案：<br></span>
          多场景权益覆盖：整合购物、餐饮、生活服务等多领域资源，为消费者提供丰富多样的权益选择。<br>
          精准营销支持：利用大数据分析用户购买行为，推送个性化优惠券和促销活动，提升营销转化率。<br>
          会员管理优化：为品牌搭建会员权益体系，提供积分管理、会员等级升级等功能，增强用户粘性。<br>
          全渠道推广：通过线上线下融合的权益分发方式，帮助品牌高效覆盖目标用户群体。<br>
        </div>
        <div
          style="display: flex;justify-content: space-between;align-items: center;text-align: center;margin-top: 2rem;">
          <img style="width: 100%;" :src="require('../../public/img/trends/placeList2.png')" />
        </div>
        <div
          style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 2rem;cursor: pointer;border-bottom: 0.2rem solid #e9e9e9;height: 5rem;"
          @click="gotoJoin">
          获取解决方案 >
        </div>
      </div>

      <div style="width: 80%;margin: 0 auto;">
        <div style="font-size: 1.8rem;text-align: center;color: #313131;padding-top: 3rem;font-weight: bold;">
          出行行业解决方案
        </div>
        <div
          style="font-size: 1rem;line-height: 2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 2rem;">
          <span style="font-weight: bolder;font-size: 1.2rem;">场景痛点：<br></span>
          用户需求多样，从交通出行到旅游服务，场景复杂且碎片化。<br>
          用户期待更便捷的权益获取方式，提升整体出行体验。<br>
        </div>
        <div
          style="font-size: 1rem;line-height: 2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 1rem;">
          <span style="font-weight: bolder;font-size: 1.2rem;">解决方案：<br></span>
          跨场景出行权益：整合公共交通、网约车、共享出行、停车服务等权益，打造一站式出行解决方案。<br>
          动态权益分发：根据用户出行习惯与地理位置，实时推送车票折扣、用车券等权益，提升使用率。<br>
          个性化旅行推荐：结合旅游资源，提供景区门票优惠、酒店折扣等附加权益，提升出行附加值。<br>
          用户行为分析：基于出行数据分析，为合作伙伴提供优化服务和挖掘增值机会的建议。<br>
        </div>
        <div
          style="display: flex;justify-content: space-between;align-items: center;text-align: center;margin-top: 2rem;">
          <img style="width: 100%;" :src="require('../../public/img/trends/placeList3.png')" />
        </div>
        <div
          style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 2rem;cursor: pointer;border-bottom: 0.2rem solid #e9e9e9;height: 5rem;"
          @click="gotoJoin">
          获取解决方案 >
        </div>
      </div>

      <div style="width: 80%;margin: 0 auto;">
        <div style="font-size: 1.8rem;text-align: center;color: #313131;padding-top: 3rem;font-weight: bold;">
          娱乐行业解决方案
        </div>
        <div
          style="font-size: 1rem;line-height: 2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 2rem;">
          <span style="font-weight: bolder;font-size: 1.2rem;">场景痛点：<br></span>
          用户期望个性化的娱乐服务，但内容资源分散。<br>
          付费内容推广难度大，需要增强用户体验和转化率。<br>
        </div>
        <div
          style="font-size: 1rem;line-height: 2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 1rem;">
          <span style="font-weight: bolder;font-size: 1.2rem;">解决方案：<br></span>
          内容权益整合：提供在线视频、音乐、游戏、线下娱乐等权益，满足用户多样化的娱乐需求。<br>
          智能内容推荐：基于用户观影、收听与游戏行为，推荐高匹配度的内容权益，提高用户满意度。<br>
          会员专享权益：设计平台专属会员权益，如免费观影券、折扣游戏道具，提升用户留存率。<br>
          效果监测与优化：实时分析用户权益使用行为，帮助内容平台优化运营策略，提升市场竞争力。<br>
        </div>
        <div
          style="display: flex;justify-content: space-between;align-items: center;text-align: center;margin-top: 2rem;">
          <img style="width: 100%;" :src="require('../../public/img/trends/placeList4.png')" />
        </div>
        <div
          style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 2rem;cursor: pointer;border-bottom: 0.2rem solid #e9e9e9;height: 5rem;"
          @click="gotoJoin">
          获取解决方案 >
        </div>
      </div>

      <div style="width: 80%;margin: 0 auto;padding-bottom: 3rem;">
        <div style="font-size: 1.8rem;text-align: center;color: #313131;padding-top: 3rem;font-weight: bold;">
          企业福利解决方案
        </div>
        <div
          style="font-size: 1rem;line-height: 2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 2rem;">
          <span style="font-weight: bolder;font-size: 1.2rem;">场景痛点：<br></span>
          企业需要创新的员工福利方案，提升员工满意度与企业凝聚力。<br>
          福利形式单一，难以满足不同员工的个性化需求。<br>
        </div>
        <div
          style="font-size: 1rem;line-height: 2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 1rem;">
          <span style="font-weight: bolder;font-size: 1.2rem;">解决方案：<br></span>
          灵活福利包设计：整合多样化的权益资源，包括健康体检、健身卡、购物券、教育资源等，满足员工个性化需求。<br>
          福利平台搭建：提供专属福利管理系统，让企业高效分配和管理员工福利。<br>
          员工行为洞察：通过数据分析了解员工偏好，优化福利设计，提高员工满意度与参与度。<br>
          合规性保障：采用严格的数据管理和隐私保护措施，确保企业在福利分发过程中的合规性。<br>
        </div>
        <div
          style="display: flex;justify-content: space-between;align-items: center;text-align: center;margin-top: 2rem;">
          <img style="width: 100%;" :src="require('../../public/img/trends/placeList5.png')" />
        </div>
        <div
          style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 2rem;cursor: pointer;"
          @click="gotoJoin">
          获取解决方案 >
        </div>
      </div>
    </div>

    <bottom></bottom>

    <!-- <customerservice></customerservice> -->

  </div>
</template>

<script>
  export default {
    data() {
      return {

      };
    },

    methods: {
      routerPush(num) {
        this.$router.push({ path: '/trendsNum', query: { id: num } })
      },
      gotoJoin() {
        this.$router.push({ path: '/join' })
      }
    },
  };
</script>

<style scoped>
  * {
    margin: 0;
    padding: 0;
  }

  .w100 {
    width: 100%;
  }

  .w90 {
    width: 90%;
  }

  .w60 {
    width: 60%;
  }

  .w30 {
    width: 30%;
  }

  .w200 {
    width: 200px;
  }

  .minwidth {
    min-width: 1000px;
  }

  .flex {
    display: flex;
  }

  .textcent {
    text-align: center;
  }

  .fontsize50 {
    font-size: 50px;
  }

  .fontsize20 {
    font-size: 20px;
  }

  .fontsize14 {
    font-size: 14px;
  }

  .fontsize12 {
    font-size: 12px;
  }

  .fontcolorb0 {
    color: #b0b0b0;
  }

  .margincent {
    margin: 0 auto;
  }

  .margincentY {
    margin: auto 0;
  }

  .margintop20 {
    margin-top: 20px;
  }

  .margintop50 {
    margin-top: 50px;
  }

  .margintop70 {
    margin-top: 70px;
  }

  .marginleft20 {
    margin-left: 20px;
  }

  .marginbot40 {
    margin-bottom: 40px;
  }

  .paddingtop20 {
    padding-top: 20px;
  }

  .title {
    color: #000
  }

  .title:hover {
    color: #bc9158;
  }

  .positionrel {
    position: relative;

    height: 320px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 1920px;
    -webkit-filter: contrast(50%);
    filter: contrast(50%);
  }

  .textAlign_justify {
    text-align: justify;
  }

  .whitespace {
    white-space: pre-wrap;
    line-height: 30px;
  }

  @media screen and (max-width: 750px) {
    .minwidth {
      min-width: 0px;
    }

    .mw100 {
      width: 100vw;
    }

    .flexnone {
      display: block;
    }

    .w80 {
      width: 80%;
    }

    .w300 {
      width: 300px;
    }

    .fontsize30 {
      font-size: 30px;
    }

    .marginleft20 {
      margin-left: 0;
    }

    .title {
      color: #bc9158;
    }

    .positionrel {
      position: relative;

      height: 179px;
      overflow: hidden;
    }

    .positionabs {
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      width: 780px;
    }
  }
</style>
<template>
  <div class="minwidth w100 mw100">
    <tabbar></tabbar>

    <div class="positionrel w100">
      <img :src="require('../../public/img/join/pic1.png')" class="positionabs" />
      <div style="color: #fff;font-size: 3rem;position: absolute;top: 8rem;left: 50%;transform: translate(-50%);">
        行业资讯
      </div>
    </div>

    <div style="background-color: #f7f7f7;width: 100%;padding: 3rem 0 1rem 0;">
      <div v-for="(item,index) in msglist" style="display: flex;padding: 1rem;background-color: #fff;width: 50%;margin: 0 auto;justify-content: space-around;border-radius: 1rem;margin-bottom: 2rem;cursor: pointer;" @click="gotoNewsDetail(index)">
        <div style="width: 40%;text-align: center;">
          <img style="height: 12rem;width: 95%;object-fit:cover;" :src="item.url" alt="">
        </div>
        <div style="width: 50%;">
          <div style="font-size: 1.3rem;margin-top: 1rem;height: 3rem;">
            {{ item.title }}
          </div>
          <div style="font-size: 0.9rem;overflow: hidden;text-overflow: ellipsis;text-align: justify;margin-top: 2rem;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;line-clamp: 4;">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msglist: [
      {
          url: require('../../public/img/news/news0.png'),
          title: '5G-A护航低空经济背后，中国移动的“算”与“谋”',
          content: '通信世界网消息（CWW）低空经济作为国家战略性新兴产业，又是新质生产力的典型代表，正成为拉动新一轮经济增长的重要引擎。尤其是今年步入5G-A商用元年以来，伴随着5G-A技术的创新应用与持续突破，低空经济正迎来前所未有的发展机遇。就技术特性而言，5G-A作为5G的进阶版本，超高速率、超大连接数、超低时延等特性可为低空经济提供强大的技术支持和保障；就网络支撑来看，5G-A通感一体技术全天候、无死角、高精度、低成本的核心优势使得空中无人机的远程实时操控、高清视频回传，地面交通管理的数字化、智能化成为可能。'
        },
        {
          url: require('../../public/img/news/news2.png'),
          title: '“死磕”小米？保时捷启用中国高管，本地研发本地采购',
          content: '近日，保时捷官方宣布在中国设立技术部，这一重大举措标志着保时捷在中国市场的战略布局进一步深化，旨在充分利用中国在新能源汽车领域的领先优势，尤其是强大的供应链资源，推动其在中国市场的持续发展。在全球汽车产业深度变革的当下，中国市场的重要性不言而喻。中国不仅是全球最大的汽车消费市场，在新能源汽车领域更是引领全球，其技术研发、基础设施建设以及供应链体系等方面均具备显著优势。'
        },
        {
          url: require('../../public/img/news/news4.png'),
          title: '中国联通上涨2.31%，报5.32元/股',
          content: '资料显示，中国联合网络通信股份有限公司位于北京市西城区金融大街21号4楼，公司主要业务为提供联网通信和算网数智服务，设有覆盖全国及境外的现代通信网络，服务于党政军系统、各行各业及广大人民群众，致力于技术领先、高度集成的“全覆盖、全在线、全云化、绿色化、一站式”数字化服务。关键经营数据方面，公司在2024年《财富》世界500强中位列第279位，持有控股的联通BVI公司间接持有联通红筹公司43.9%的股份，拥有实际控制权。'
        }
      ],

      num: 0,
    };
  },

  mounted() {
    this.num = this.$route.query.num;
  },

  methods: {
    gotoNewsDetail(num) {
      this.$router.push({ path: '/newsDetail', query: { num: num } })
    }
  },

  
};
</script>

<style scoped>
.w100 {
  width: 100%;
}

.w60 {
  width: 60%;
}

.minwidth {
  min-width: 1000px;
}

.flex {
  display: flex;
}

.textcent {
  text-align: center;
}

.textalign_justify {
  text-align: justify;
  line-height: 25px;
}

.fontsize50 {
  font-size: 50px;
}

.fontsize30 {
  font-size: 30px;
}

.fontsize20 {
  font-size: 20px;
}

.fontsize16 {
  font-size: 16px;
}

.fontsize14 {
  font-size: 14px;
}

.fontsize12 {
  font-size: 12px;
}

.fontcolorb0 {
  color: #b0b0b0;
}

.margincent {
  margin: 0 auto;
}

.margincentY {
  margin: auto 0;
}

.margintop50 {
  margin-top: 50px;
}

.margintop70 {
  margin-top: 70px;
}

.marginleft20 {
  margin-left: 20px;
}

.marginbot70 {
  margin-bottom: 70px;
}

#job:hover {
  color: #bc9158;
}

.positionrel {
  position: relative;
  height: 340px;
  overflow: hidden;
}

.positionabs {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 1920px;
  -webkit-filter: contrast(50%);
  filter: contrast(50%);
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0px;
  }

  .mw100 {
    width: 100vw;
  }

  .flexnone {
    display: block;
  }

  .w80 {
    width: 80%;
  }

  .positionrel {
    position: relative;

    height: 179px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 780px;
  }
}
</style>